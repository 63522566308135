<script>
import BaseWidgetForm from './BaseWidgetForm'
import fields from '@/components/fields/'

export default {
  extends: BaseWidgetForm,
  computed: {
    tabsFieldsSchema() {
      return [
        {
          label: 'Ogólne',
          fields: [
            ...this.getCommonFields(),
            new fields.Input({
              label: 'Tytuł',
              required: false,
              modelPath: [this, 'form', 'title'],
              multilingual: true
            }),
            new fields.WYSIWYG({
              label: 'Tekst',
              modelPath: [this, 'form', 'text'],
              required: true,
              multilingual: true,
              type: 'textWidgetEditorInit'
            })
          ]
        }
      ]
    }
  }
}
</script>
