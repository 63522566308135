<!--
<template>
  <div class="wrapper">
    <page-header :title="header.title" :add-button="header.addButton" />
    <list-header />
    <lecturer-list
      :loading="loading"
      :results="results"
      :pagination="pagination"
    />
  </div>
</template>
-->
<script>
import { debounce } from 'lodash'

export default {
  data() {
    return {
      results: [],
      loading: true,
      service: null,
      pagination: {
        hideOnSinglePage: true,
        onChange: page => {
          this.setQueryParam('page', page)
          this.$set(this.pagination, 'current', page)
        },
        current: 1
      }
    }
  },
  computed: {
    params() {
      return {
        ...this.$route.query
      }
    },
    shouldFetchList() {
      return true
    }
  },
  watch: {
    $route() {
      this.debouncedFetchData()
    }
  },
  created() {
    this.debouncedFetchData()
  },
  methods: {
    debouncedFetchData: debounce(function () {
      this.fetchData()
    }, 200),
    fetchData() {
      this.loading = true
      if (this.shouldFetchList) {
        this.service
          .fetch({}, { params: this.params })
          .then(res => {
            this.results = res.data.results
            this.setPaginationData(res.data)
          })
          .finally(() => {
            this.loading = false
          })
      } else {
        // There is business requirement to show blank list, when
        // no filters used.
        this.results = []
        this.loading = false
      }
    },
    setPaginationData(responseData) {
      this.$set(this.pagination, 'pageSize', responseData.page_size)
      this.$set(this.pagination, 'total', responseData.count)
      this.$set(this.pagination, 'current', responseData.current || 1)
    },
    deleteInstance(
      instanceParams,
      modalOptions,
      successMessage = 'The item has been deleted'
    ) {
      this.$confirm({
        title: 'Delete',
        maskClosable: true,
        content: 'Are you sure you want to delete the item?',
        okText: 'Delete',
        cancelText: 'Cancel',
        onOk: () => {
          this.service
            .delete(instanceParams)
            .then(() => {
              this.fetchData()
              this.$notification.success({ message: successMessage })
            })
            .catch(() => {
              this.$message.error('An error occurred while deleting the item.')
            })
        },
        ...modalOptions
      })
    }
  }
}
</script>
