<template>
  <div>
    <el-radio-group v-model="inputValue" size="medium">
      <el-radio-button
        v-for="language in $store.state.languages"
        :key="language.code"
        :label="language.code"
      >
        <img :src="language.flag" class="flag" /> {{ language.label }}
      </el-radio-button>
    </el-radio-group>
  </div>
</template>

<script>
export default {
  props: ['value'],
  computed: {
    inputValue: {
      set(value) {
        this.$store.state.selectedLanguage = value
      },
      get() {
        return this.$store.state.selectedLanguage
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.flag {
  height: 12px;
  margin-right: 6px;
  vertical-align: middle;
}
</style>
