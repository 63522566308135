<script>
export default {
  functional: true,
  props: {
    content: {
      type: Object
    }
  },
  render(createElement, context) {
    const { content } = context.props
    const showComponent = data =>
      data.condition !== undefined ? data.condition : true

    const getComponent = componentData =>
      createElement(
        componentData.component,
        {
          props: componentData.props,
          attrs: componentData.attrs,
          directives: componentData.directives,
          nativeOn: componentData.nativeOn,
          on: { ...componentData.listeners },
          slot: componentData.slot
          // scopedSlots: props => mapValues(componentData.scopedSlots, slot => {
          //   console.log(getComponent(slot), 'slot')
          //   return getComponent(slot)
          // })
        },
        [
          componentData.innerText,
          componentData.children &&
            componentData.children
              .filter(showComponent)
              .map(data => getComponent(data))
        ]
      )
    if (showComponent(content)) {
      return getComponent(content)
    }
    return {}
  }
}
</script>
