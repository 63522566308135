<template>
  <el-row>
    <el-col :span="24" class="main-wrapper">
      <SidebarComponent />
      <section class="content-container">
        <transition name="el-fade-in-linear" mode="out-in">
          <router-view :key="$route.path"></router-view>
        </transition>
      </section>
    </el-col>
  </el-row>
</template>

<script>
import SidebarComponent from '@/components/SidebarComponent'

export default {
  components: {
    SidebarComponent
  }
}
</script>

<style scoped lang="scss">
.main-wrapper {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  min-height: 100vh;
  background: #f3f3f4;
}

.content-container {
  margin-left: $sidebar-width;
  max-width: calc(100% - #{$sidebar-width});
  flex: 1;
  padding: 15px;
}
</style>
