<script>
import { ScheduledNotification } from '@/api'
import ListMixin from '@/crudBases/list/ListMixin'

export default {
  mixins: [ListMixin],
  data() {
    return {
      editRouteName: 'Edit ScheduledNotification',
      addRoute: { name: 'Add ScheduledNotification' },
      verboseName: 'Powiadomienia',
      verboseNamePlural: 'Powiadomienia',
      filters: {
        available: [
          {
            name: 'Data utworzenia',
            query_param: 'created',
            date: true
          },
          {
            name: 'Data wysyłki',
            query_param: 'sent_at',
            date: true
          },
          {
            name: 'Status',
            query_param: 'status',
            options: this.setAvailableStatuses
          }
        ],
        selected: {}
      },
      listFields: [
        { prop: 'id', label: 'id', width: 80 },
        { label: 'utworzono', formatter: this.formatDate('created') },
        { prop: 'type_display', label: 'Typ' },
        { prop: 'title_pl', label: 'Tytuł powiadomienia' },
        { prop: 'status_display', label: 'Status' },
        { label: 'data wysłania', formatter: this.formatDate('sent_at') }
      ]
    }
  },
  computed: {
    service() {
      return ScheduledNotification
    }
  },
  methods: {
    setAvailableStatuses(options) {
      this.service.availableStatuses().then(response => {
        this.availableStatuses = response.data
        if (options !== undefined) {
          options.push(...response.data)
        }
      })
    }
  }
}
</script>
