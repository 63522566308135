<template>
  <div class="fields">
    <el-form-item label="Data" v-bind="errorsBind('date')">
      <el-date-picker
        v-model="date__date"
        :clearable="false"
        format="yyyy-MM-dd"
      >
      </el-date-picker>
    </el-form-item>
    <el-form-item label="Godzina rozpoczęcia">
      <el-time-picker
        v-model="date__time"
        :disabled="!form.date"
        :clearable="false"
        format="HH:mm:ss"
      >
      </el-time-picker>
    </el-form-item>
    <el-form-item
      label="Czas trwania (godziny)"
      :error="getErrors(errors, ['duration_hours'])"
    >
      <el-input-number
        v-model="form.duration_hours"
        :disabled="!form.date"
        :clearable="false"
        :min="0"
      >
      </el-input-number>
    </el-form-item>
    <el-form-item
      label="Czas trwania (minuty)"
      :error="getErrors(errors, ['duration_minutes'])"
    >
      <el-input-number
        v-model="form.duration_minutes"
        :disabled="!form.date"
        :clearable="false"
        :min="0"
        :max="59"
      >
      </el-input-number>
    </el-form-item>
  </div>
</template>
<script>
import FieldsHelper from '@/mixins/form/FieldsHelper'

export default {
  mixins: [FieldsHelper],
  computed: {
    date__date: {
      set(value) {
        if (!value) return

        this.$set(
          this.form,
          'date',
          this.modifyDate(
            this.form.date || value,
            value.getFullYear(),
            value.getMonth(),
            value.getDate()
          )
        )
        this.$set(
          this.form,
          'date_to',
          this.modifyDate(
            this.form.date || value,
            value.getFullYear(),
            value.getMonth(),
            value.getDate()
          )
        )
      },
      get() {
        return this.form.date
      }
    },
    date__time: {
      set(value) {
        if (!value) return

        this.$set(
          this.form,
          'date',
          this.modifyTime(
            this.form.date,
            value.getHours(),
            value.getMinutes(),
            value.getSeconds(),
            value.getMilliseconds()
          )
        )

        // date_to field is hidden - set date_to the same as date
        this.$set(
          this.form,
          'date_to',
          this.modifyTime(
            this.form.date,
            value.getHours(),
            value.getMinutes(),
            value.getSeconds(),
            value.getMilliseconds()
          )
        )
      },
      get() {
        return this.form.date
      }
    },
    date_to__time: {
      set(value) {
        if (!value) return
        this.$set(
          this.form,
          'date_to',
          this.modifyTime(
            this.form.date,
            value.getHours(),
            value.getMinutes(),
            value.getSeconds(),
            value.getMilliseconds()
          )
        )
      },
      get() {
        return this.form.date_to
      }
    }
  },
  methods: {
    modifyDate(date, y, m, d) {
      date = new Date(date)
      return new Date(
        y,
        m,
        d,
        date.getHours(),
        date.getMinutes(),
        date.getSeconds(),
        date.getMilliseconds()
      )
    },
    modifyTime(date, h, m, s, ms) {
      date = new Date(date)
      return new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        h,
        m,
        s,
        ms
      )
    }
  }
}
</script>
<style scoped lang="scss">
.fields {
  display: grid;
  grid-column-gap: 50px;
  grid-row-gap: 20px;
  grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
  max-width: 1500px;
}
</style>
