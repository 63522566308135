<template>
  <div class="wrapper">
    <el-button
      v-if="!value"
      class="fluid fill-link-button"
      type="primary"
      @click="initForm"
    >
      Uzupełnij odnośnik
    </el-button>
    <div v-else>
      <div class="inline-form-group">
        <el-form-item
          v-show="!hideLinkLabel"
          :label="labelMultilingualPlaceholder"
          :error="getErrors(errors, [addLanguageSuffix('label')])"
        >
          <el-input v-model="value[addLanguageSuffix('label')]" />
        </el-form-item>
        <el-button
          v-if="nullable"
          type="warning"
          class="remove-button"
          size="mini"
          @click="removeLink"
        >
          Usuń odnośnik
        </el-button>
      </div>
      <div class="inline-form-group">
        <el-form-item label="Typ linku" required :error="anchorErrors">
          <el-select v-model="anchorType" placeholder="Wybierz typ linku">
            <el-option
              v-for="option in anchorOptions"
              :key="option.value"
              :label="option.label"
              :value="option.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Otwieraj w nowym oknie" label-width="200px">
          <el-switch v-model="value.target_blank"></el-switch>
        </el-form-item>
      </div>
      <el-form-item
        v-if="!value.content_type && !internalOnly"
        :label="`URL (${current_language})`"
        required
        :error="getErrors(errors, [urlFieldName])"
      >
        <el-input v-model="value[urlFieldName]" />
      </el-form-item>
      <div v-if="contentTypeOptions" class="inline-form-group">
        <el-form-item
          :label="contentTypeOptions.model_name"
          required
          :error="getErrors(errors, ['object_id'])"
        >
          <el-select
            v-model="value.object_id"
            filterable
            placeholder="Wybierz z listy"
            @change="delete value['widget']"
          >
            <el-option
              v-for="option in contentTypeOptions.objects"
              :key="option.value"
              :label="option.label"
              :value="option.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          v-if="availableWidgets.length > 0"
          v-loading="loadingWidgets"
          label="Nazwa widgetu"
          :error="getErrors(errors, ['widget'])"
        >
          <span slot="label">
            Nazwa widgetu
            <el-tooltip
              content="Widget, do którego domyślnie przescrolluje się strona po kliknięciu w link."
              placement="bottom"
            >
              <el-button class="widget-info"
                ><i class="fa fa-question-circle"></i
              ></el-button>
            </el-tooltip>
          </span>
          <el-select
            v-model="value.widget"
            clearable
            placeholder="Wybierz widget"
          >
            <el-option
              v-for="option in availableWidgets"
              :key="option.id"
              :label="option.label"
              :value="option.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </div>
      <div v-if="isAttachmentType">
        <el-form-item label="Wgraj nowy załącznik">
          <attachment-input @input="attachmentUploaded" />
        </el-form-item>
      </div>
      <el-form-item
        v-if="showIconInput"
        :label="`Ikonka (${current_language})`"
        :error="getErrors(errors, [iconFieldName])"
      >
        <image-input
          v-model="value[iconFieldName]"
          help="Wymagana wysokość grafiki to 16px."
        />
      </el-form-item>
    </div>
    <div v-if="typeof errors === 'string'" class="el-form-item__error">
      {{ errors }}
    </div>
  </div>
</template>
<script>
import { Link } from '@/api'
import ImageInput from '@/components/inputs/ForeignKeys/ImageInput'

export default {
  components: {
    ImageInput
  },
  props: {
    value: { type: Object, required: false },
    errors: {
      type: [Object, String, Function],
      default: () => {}
    },
    labelPlaceholder: {
      type: String,
      default: 'Tekst hiperłącza'
    },
    nullable: {
      type: Boolean,
      default: true
    },
    hideLinkLabel: {
      type: Boolean,
      default: false
    },
    internalOnly: {
      type: Boolean,
      default: false
    },
    showIconInput: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      contentTypes: [],
      availableWidgets: [],
      loadingWidgets: false
    }
  },
  computed: {
    anchorErrors() {
      return this.getErrors(this.errors, ['anchor_type'])
    },
    availableLinkOptions() {
      return this.$store.state.availableLinkOptions
    },
    contentTypeOptions() {
      if (!this.value) return
      const selectedContentType = this.value.content_type
      const matchingContentType = option =>
        option.content_type_id === selectedContentType
      return this.availableLinkOptions.find(matchingContentType)
    },
    anchorOptions() {
      const availableContentTypes = this.availableLinkOptions.map(option => ({
        value: option.content_type_id,
        label: option.model_name
      }))
      if (this.internalOnly) {
        return availableContentTypes
      } else {
        return [
          ...availableContentTypes,
          {
            label: 'URL',
            value: 'url'
          }
        ]
      }
    },
    anchorType: {
      set(value) {
        if (value === 'url') {
          this.$set(this.value, 'object_id', null)
          this.$set(this.value, 'content_type', null)
        } else {
          this.clearUrlFields()
          this.$set(this.value, 'content_type', value)
          this.$set(this.value, 'object_id', null)
        }
      },
      get() {
        if (this.internalOnly) {
          if (this.value.content_type === undefined) {
            const anchorOptions = this.anchorOptions
            if (anchorOptions && anchorOptions.length) {
              this.$set(this.value, 'content_type', anchorOptions[0].value)
            }
          }
          return this.value.content_type
        }
        return this.value.content_type || 'url'
      }
    },
    isAttachmentType() {
      const contentType = this.contentTypeOptions
      return contentType && contentType.model === 'attachment'
    },
    current_language() {
      return this.$store.state.selectedLanguage
    },
    iconFieldName() {
      return `icon_${this.current_language}`
    },
    urlFieldName() {
      return `url_${this.current_language}`
    },
    labelMultilingualPlaceholder() {
      return `${this.labelPlaceholder} (${this.current_language})`
    }
  },
  watch: {
    'value.object_id': {
      immediate: true,
      handler: function (value) {
        this.setAvailableWidgets(value)
      }
    },
    contentTypeOptions: {
      handler: function (value) {
        if (value && value.objects && value.objects.length === 1) {
          this.$nextTick(() => {
            this.value.object_id = value.objects[0].value
          })
        }
      }
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    attachmentUploaded(attachmentId) {
      this.$set(this.value, 'object_id', attachmentId)
      this.fetchData()
    },
    initForm() {
      this.$emit('input', { [this.urlFieldName]: '' })
    },
    removeLink() {
      this.$emit('input', null)
    },
    fetchData() {
      this.$store.dispatch('fetchAvailableLinkOptions')
    },
    setAvailableWidgets(objectId) {
      if (!this.isAttachmentType && objectId) {
        this.loadingWidgets = true
        Link.availableWidgets({
          basepage_pk: objectId
        })
          .then(res => {
            this.availableWidgets = res.data
          })
          .finally(() => {
            this.loadingWidgets = false
          })
      } else {
        this.availableWidgets = []
      }
    },
    clearUrlFields() {
      this.$store.state.languages.forEach(language => {
        this.$set(this.value, `url_${language.code}`, '')
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.widget-info {
  padding: 9px;
}

.remove-button {
  display: inline-block;
  margin: 0 0 22px 11px;
}

.fluid {
  width: 100%;
}

.wrapper {
  background: #f7f7f7;
  padding: 22px;
  border: 1px solid #e2e2e2;
  position: relative;
  margin-bottom: 22px;
}

.fill-link-button {
  margin-bottom: 22px;
}
</style>
