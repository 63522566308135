import { get } from 'lodash'

export default class FieldBase {
  constructor({ label, modelPath, required, multilingual, condition, copyTo }) {
    if (typeof modelPath[0] === 'string') {
      throw Error('First element in modelPath must be "this"')
    }
    this.condition = condition
    this.modelPath = modelPath
    this.vue = modelPath.shift()
    this.multilingual = multilingual
    this.copyTo = copyTo || {}

    if (multilingual) {
      this.addTranslatedFieldsToCopyTo()
      this.addSuffixToPath()
    }

    const error = this.getErrorsForPath(modelPath)
    this.formData = this.getModelPathDeconstructed()

    this.component = 'el-form-item'
    this.attrs = {
      label: multilingual ? `${label} (${this.currentLanguage})` : label,
      error,
      required
    }
  }

  get currentLanguage() {
    return this.vue.$store.state.selectedLanguage
  }

  getComponentWithVModel(component) {
    const { form, fieldName, fieldValue } = this.formData
    return {
      component: component,
      listeners: {
        input: value => this.setValues(form, fieldName, value)
      },
      attrs: {
        value: fieldValue
      }
    }
  }

  setValues(form, fieldName, value) {
    const oldValue = get(form, fieldName)
    this.vue.$set(form, fieldName, value)

    Object.keys(this.copyTo).forEach(key => {
      const sameLanguage = this.copyTo[key]['sameLanguage']
      if (sameLanguage && !this.isFieldsSameLanguage(fieldName, key)) {
        return
      }
      const stripHtml = this.copyTo[key]['stripHtml']
      const newValue = stripHtml ? this.escapeHtml(value) : value
      const escapedOldValue = stripHtml ? this.escapeHtml(oldValue) : oldValue
      const copyToValue = get(form, key)
      if (!copyToValue || copyToValue === escapedOldValue) {
        this.vue.$set(form, key, newValue)
      }
    })
  }

  isFieldsSameLanguage(fieldName, anotherFieldName) {
    return (
      this.multilingual && fieldName.slice(-3) === anotherFieldName.slice(-3)
    )
  }

  escapeHtml(html) {
    let tmp = document.createElement('DIV')
    tmp.innerHTML = html
    return tmp.textContent || tmp.innerText || ''
  }

  addTranslatedFieldsToCopyTo() {
    const defaultLanguage = this.vue.$store.state.defaultLanguage
    if (this.currentLanguage !== defaultLanguage) {
      return
    }

    const languages = this.vue.$store.state.languages
    languages.forEach(language => {
      if (language !== defaultLanguage) {
        const fieldName = `${this.modelPath[this.modelPath.length - 1]}_${
          language.code
        }`
        this.copyTo[fieldName] = { stripHtml: false }
      }
    })
  }

  addSuffixToPath() {
    const fieldName = `${this.modelPath[this.modelPath.length - 1]}_${
      this.currentLanguage
    }`
    this.modelPath[this.modelPath.length - 1] = fieldName
    return this.modelPath
  }

  getErrorsForPath(path) {
    const errorsPath = path[0] === 'form' ? path.slice(1) : path
    return get(this.vue.errors, errorsPath)
  }

  getModelPathDeconstructed() {
    const path = this.modelPath
    return {
      fieldValue: get(this.vue, path),
      fieldName: path[path.length - 1],
      form: get(this.vue, path.slice(0, path.length - 1))
    }
  }
}
