<template>
  <form-wrapper @submit="onSubmit">
    <div slot="box" :loading="loading">
      <form-nav
        slot="navbar"
        :loading="loading"
        :redirect-route="redirectRoute"
        @submit="onSubmit"
      >
        <component-compiler
          v-for="(component, i) in getNavButtons()"
          :key="`form-${i}`"
          :content="component"
        />
      </form-nav>
      <el-row :gutter="24" style="padding-top: 20px">
        <el-col :sm="24" :md="17" class="box">
          <component-compiler
            v-for="(component, i) in getFields()"
            :key="`form-${i}`"
            :content="component"
          />
        </el-col>
        <el-col :sm="24" :md="7" class="box">
          <component-compiler
            v-for="(component, i) in getSidebarFields()"
            :key="`form-${i}`"
            :content="component"
          />
        </el-col>
      </el-row>
    </div>
  </form-wrapper>
</template>

<script>
import { User } from '@/api'
import FormMixin from '@/crudBases/form/FormMixin'
import fields from '@/components/fields/'
import RoleSelect from './RoleSelect'

export default {
  mixins: [FormMixin],
  data() {
    return {
      form: {},
      redirectRoute: { name: 'User List' }
    }
  },
  computed: {
    service() {
      return User
    }
  },
  created() {
    this.$store.dispatch('fetchProfiles')
  },
  methods: {
    getFields() {
      return [
        new fields.Input({
          label: 'Telefon',
          type: 'phone',
          required: true,
          modelPath: [this, 'form', 'phone']
        }),
        new fields.Input({
          label: 'Imię i nazwisko',
          modelPath: [this, 'form', 'full_name']
        }),
        new fields.Date({
          format: 'yyyy-MM-dd',
          valueFormat: 'yyyy-MM-dd',
          label: 'Data urodzenia',
          modelPath: [this, 'form', 'birth_date']
        }),
        new fields.Switch({
          label: 'Użytkownik aktywny',
          modelPath: [this, 'form', 'is_active']
        }),
        new fields.Switch({
          label: 'Użytkownik ma dostęp do panelu',
          modelPath: [this, 'form', 'is_staff']
        }),
        new RoleSelect({
          label: 'Rola',
          modelPath: [this, 'form'],
          options: this.$store.state.profiles
        })
      ]
    },
    getSidebarFields() {
      return []
    }
  }
}
</script>
