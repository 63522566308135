<script>
import BaseWidgetForm from './BaseWidgetForm'
import fields from '@/components/fields/'

export default {
  extends: BaseWidgetForm,
  data() {
    return {
      arrayFields: ['items']
    }
  },
  computed: {
    tabsFieldsSchema() {
      return [
        {
          label: 'Ogólne',
          fields: [
            ...this.getCommonFields(),
            new fields.Header({
              text: 'Bąbelki',
              type: 'h4'
            }),
            new fields.Formset({
              modelPath: [this, 'form', 'items'],
              items: this.form.items.map((item, i) => ({
                headerLabel: `Bąbelek ${i + 1}`,
                fields: [
                  new fields.Input({
                    label: 'Label',
                    modelPath: [this, 'form', 'items', i, 'label'],
                    required: true,
                    multilingual: true
                  }),
                  new fields.Link({
                    label: 'Link',
                    required: true,
                    modelPath: [this, 'form', 'items', i, 'link'],
                    hideLinkLabel: true
                  })
                ]
              }))
            })
          ]
        }
      ]
    }
  }
}
</script>
