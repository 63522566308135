<template>
  <div>
    <input ref="file" type="file" style="display: none" @change="sendFile" />
    <editor
      ref="editor"
      :init="initType"
      :value="value"
      @input="$emit('input', $event)"
    >
    </editor>
  </div>
</template>
<script>
import { Attachment } from '@/api'
import { get } from 'lodash'
// skins
// eslint-disable-next-line no-unused-vars
import tinymce from 'tinymce/tinymce'
import 'tinymce/skins/ui/oxide/skin.min.css'
// plugins
import 'tinymce/themes/silver'
import 'tinymce/plugins/paste'
import 'tinymce/plugins/link'
import 'tinymce/plugins/advlist'
import 'tinymce/plugins/image'
import 'tinymce/plugins/autoresize'
import 'tinymce/plugins/textcolor'
import 'tinymce/plugins/colorpicker'
import 'tinymce/plugins/imagetools'
import 'tinymce/plugins/media'
import 'tinymce/plugins/preview'
import 'tinymce/plugins/lists'
import 'tinymce/plugins/code'

export default {
  props: {
    value: { type: String },
    type: {
      type: String,
      default: 'editorInit',
      validator: value => {
        return [
          'simpleEditorInit',
          'editorInit',
          'textWidgetEditorInit',
          'faqWidgetEditorInit'
        ].includes(value)
      }
    }
  },
  data() {
    return {
      callback: () => {},
      simpleEditorInit: {
        menubar: false,
        plugins:
          'advlist image autoresize imagetools textcolor colorpicker paste media link code',
        toolbar: 'bold italic backcolor forecolor | link code',
        file_picker_callback: this.filePickerCallback
      },
      editorInit: {
        menubar: false,
        image_advtab: true,
        extended_valid_elements:
          'svg[*],defs[*],pattern[*],desc[*],metadata[*],g[*],mask[*],path[*],line[*],marker[*],rect[*],circle[*],ellipse[*],polygon[*],polyline[*],linearGradient[*],radialGradient[*],stop[*],image[*],view[*],text[*],textPath[*],title[*],tspan[*],glyph[*],symbol[*],switch[*],use[*]',
        plugins:
          'advlist image autoresize imagetools textcolor colorpicker paste media link lists code',
        toolbar:
          'undo redo | formatselect | alignleft aligncenter alignright alignjustify | bold italic underline | bullist numlist | removeformat | media link code image',
        block_formats:
          'Paragraph=p;Header 2=h2;Header 3=h3;Header 4=h4;Small=h6',
        content_css: '/static/wyswig.css',
        advlist_bullet_styles: 'default',
        paste_data_images: true,
        file_picker_callback: this.filePickerCallback
      },
      textWidgetEditorInit: {
        menubar: false,
        plugins: 'link',
        toolbar:
          'undo redo | formatselect | bold underline | removeformat | link',
        block_formats: 'Paragraph=p;Header 2=h2;Header 3=h3',
        content_css: '/static/wyswig.css',
        advlist_bullet_styles: 'default'
      },
      faqWidgetEditorInit: {
        menubar: false,
        plugins: 'link',
        toolbar:
          'undo redo | formatselect | bold underline | removeformat | link',
        block_formats: 'Paragraph=p;Header 2=h2;Header 3=h3',
        content_css: '/static/wyswig.css',
        advlist_bullet_styles: 'default'
      }
    }
  },
  computed: {
    initType() {
      return get(this, this.type)
    }
  },
  watch: {
    value(value) {
      if (value === undefined) {
        this.$emit('input', '')
      }
    }
  },
  methods: {
    filePickerCallback(callback) {
      this.callback = callback
      this.$refs.file.click()
    },
    sendFile(event) {
      const file = event.target.files[0]
      const data = new FormData()
      data.append('file', file, file.name)
      return Attachment.upload(data)
        .then(response => {
          this.callback(response.data.file, { text: file.name })
        })
        .catch(this.uploadError)
    },
    uploadError() {
      this.$message({
        message: 'Wystąpił problem podczas wysyłania pliku.',
        type: 'error'
      })
    }
  }
}
</script>
<style>
.tox-tinymce-aux {
  z-index: 3000;
}
</style>
