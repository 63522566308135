<template>
  <div>
    <widgets-dialogs ref="widgetDialogs" :widgets="widgets" :errors="errors" />
    <global-widgets-dialogs
      ref="globalWidgetDialogs"
      :widgets="widgets"
      :errors="errors"
    />
    <div>
      <div class="widgets">
        <div v-if="nonFieldErrors" class="non-field-error">
          {{ nonFieldErrors }}
        </div>
        <p v-show="widgets.length === 0" class="widgets__list--empty">
          Brak widgetów do wyświetlenia - dodaj nowy.
        </p>
        <ul class="widgets__list">
          <li
            v-for="(widget, i) in widgets"
            :key="`${widget.id}  ${i}`"
            class="widget"
            :class="{ 'widget--error': !isEmpty(errors[i]) }"
            @click="editWidget(i)"
          >
            <div class="preview-text">
              <strong>{{ getWidgetTypeLabel(widget) }}</strong>
            </div>
            <div class="buttons">
              <el-button
                v-if="i !== widgets.length - 1"
                size="small"
                title="Przesuń w dół"
                @click.stop="changeOrder(widgets, i, 1)"
              >
                <i class="fa fa-arrow-down"></i>
              </el-button>
              <el-button
                :style="{ opacity: i !== 0 ? 1 : 0 }"
                size="small"
                title="Przesuń do góry"
                @click.stop="changeOrder(widgets, i, -1)"
              >
                <i class="fa fa-arrow-up"></i>
              </el-button>
              <el-button
                type="danger"
                size="small"
                @click.stop="removeWidget(i)"
              >
                <i class="fa fa-remove"></i>
              </el-button>
            </div>
          </li>
        </ul>

        <el-button type="warning" @click="openAddWidgetDialog">
          <i class="fa fa-plus-circle" /> Dodaj nowy widget
        </el-button>
        <el-button type="warning" @click="openAddGlobalWidgetDialog">
          <i class="fa fa-plus-circle" /> Wgraj nowy widget globalny
        </el-button>
      </div>
    </div>
  </div>
</template>
<script>
import { isEmpty } from 'lodash'
import WidgetsDialogs from '@/components/widgets/WidgetsDialogs'
import GlobalWidgetsDialogs from '@/components/widgets/GlobalWidgetsDialogs'

export default {
  components: {
    WidgetsDialogs,
    GlobalWidgetsDialogs
  },
  props: {
    widgets: Array,
    errors: [Array, String]
  },
  data() {
    return {
      selectedWidget: {},
      previews: []
    }
  },
  computed: {
    nonFieldErrors() {
      const isString = typeof this.errors === 'string'
      return isString && this.errors
    }
  },
  beforeMount() {
    this.fetchData()
  },
  methods: {
    isEmpty,
    fetchData() {
      this.$store.dispatch('fetchAvailableWidgets')
      this.$store.dispatch('fetchWidgetTypes')
      this.$store.dispatch('fetchGlobalWidget')
    },
    editWidget(widgetIndex) {
      this.$refs.widgetDialogs.editWidget(widgetIndex)
    },
    openAddWidgetDialog() {
      const lastIndex = this.widgets.length
      this.$refs.widgetDialogs.openAddWidgetDialog(lastIndex)
    },
    openAddGlobalWidgetDialog() {
      const lastIndex = this.widgets.length
      this.$refs.globalWidgetDialogs.openAddGlobalWidgetDialog(lastIndex)
    },
    removeWidget(widgetIndex) {
      const widget = this.widgets[widgetIndex]
      this.removeItemFromFormset(this.widgets, widget)
      this.removeItemFromFormset(this.errors, this.errors[widgetIndex])
    },
    getWidgetTypeLabel(widget) {
      const resourcetype = widget.resourcetype
      const findByResourcetype = type => type.resourcetype === resourcetype
      const widgetType = this.$store.state.widgetTypes.find(findByResourcetype)
      const label = widgetType && widgetType.label
      const working_name = widget.is_global && widget.working_name
      return working_name || label || resourcetype
    }
  }
}
</script>
<style lang="scss" scoped>
.non-field-error {
  color: $error-color;
}

.widgets {
  &__list {
    margin-bottom: 28px;
    list-style-type: none;
    padding: 0;

    &--empty {
      font-size: 13px;
    }
  }
}

.widget {
  display: flex;
  padding: 15px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-top: 1px solid #f3f3f3;
  border-bottom: 1px solid #f3f3f3;
  cursor: pointer;
  position: relative;

  &:hover {
    background: #f3f3f3;
  }

  &--error {
    border-left: 3px solid $error-color;
    color: $error-color;
  }

  &__icon {
    max-width: 100%;
    max-height: 43px;
    display: inline-block;
    text-align: right;
  }
}
</style>
