<script>
import BaseWidgetForm from './BaseWidgetForm'
import fields from '@/components/fields/'
import { Event } from '@/api'

export default {
  extends: BaseWidgetForm,
  data() {
    return {
      arrayFields: ['events'],
      initialValues: {
        order_by: 'as_in_list'
      }
    }
  },
  computed: {
    tabsFieldsSchema() {
      return [
        {
          label: 'Ogólne',
          fields: [
            ...this.getCommonFields(),
            new fields.Select({
              label: 'Sortuj',
              modelPath: [this, 'form', 'order_by'],
              options: [
                {
                  label: 'Alfabetycznie',
                  value: 'full_name'
                },
                {
                  label: 'Wg priorytetu',
                  value: 'as_in_list'
                }
              ]
            }),
            new fields.ForeignKey({
              label: 'Artyści',
              modelPath: [this, 'form', 'events'],
              labelField: 'artist_full_name_pl',
              valueField: 'id',
              clearable: true,
              multiple: true,
              sortable: true,
              allowRefresh: true,
              service: Event
            })
          ]
        }
      ]
    }
  }
}
</script>
