<template>
  <div class="drop" @dragover.prevent @drop="onDrop">
    <label class="btn display-inline">
      PRZECIĄGNIJ I UPUŚĆ LUB
      <input ref="fileInput" type="file" name="value" @change="onChange" />
    </label>
  </div>
</template>

<script>
export default {
  props: {
    extensions: {
      type: Array,
      default: () => ['.jpg', '.jpeg', '.png', '.webp']
    }
  },
  methods: {
    onDrop(e) {
      e.stopPropagation()
      e.preventDefault()
      let files = e.dataTransfer.files
      this.$emit('submit', files[0])
    },
    onChange(e) {
      let files = e.target.files
      const file = files[0]
      if (this.extensions && this.extensions.length) {
        const fileExtension = '.' + file.name.split('.').pop()
        if (!this.extensions.includes(fileExtension)) {
          this.$message({
            message: `Nie możesz użyć tego zdjęcia. Akceptowane typy zdjęć: ${this.extensions.join(
              ', '
            )}.`,
            type: 'error',
            duration: 2500
          })
          this.clear()
          return
        }
      }

      this.$emit('submit', file)
    },
    clear() {
      this.$refs.fileInput.value = ''
    }
  }
}
</script>

<style lang="scss" scoped>
.drop {
  border: 2px dashed #ccc;
  padding: 40px;
  background-color: #fafafa;
  height: 100%;
  position: relative;
  max-width: 100%;
  text-align: center;
  margin: 0 auto;
}

.drop img {
  max-width: 100%;
}
</style>
