<template>
  <div>
    <el-row>
      <el-col class="box" :span="24">
        <div class="list__header">
          <router-link v-if="addRoute" :to="addRoute">
            <el-button type="primary" class="fluid">
              Dodaj {{ verboseName }} <i class="el-icon-plus"></i>
            </el-button>
          </router-link>

          <p class="list__title">
            {{ getRouteName($route) }}
          </p>
          <div class="search-items">
            <el-select
              v-model="filters.selected.catalog"
              filterable
              :clearable="true"
              placeholder="Wybierz katalog"
              class="catalog-search"
              @change="debouncedFetchData"
            >
              <el-option
                v-for="item in catalogOptions"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
            <search-list-form
              v-if="includeSearchFilter"
              v-model="filters.selected.search"
              @submit="debouncedFetchData"
            />
          </div>
        </div>
        <el-row class="header__actions">
          <el-col>
            <component-compiler
              v-for="(component, i) in headerActions()"
              :key="`header-action-${i}`"
              class="margin-right-15"
              :content="component"
            />
          </el-col>
        </el-row>
        <div v-loading="listLoading" class="grid">
          <div v-for="(image, i) in items" :key="i" class="item">
            <el-card style="margin-bottom: 20px; position: relative">
              <router-link :to="getEditRoute(image)">
                <div class="thumbnail__wrapper">
                  <img
                    class="thumbnail"
                    :src="
                      image.thumbnail ? image.thumbnail.url : image.file_url
                    "
                    alt=""
                  />
                </div>
                <el-link>{{ image.title }}</el-link>
              </router-link>
              <el-button
                class="remove-button"
                circle
                size="mini"
                icon="el-icon-delete"
                type="danger"
                title="Usuń zdjęcie"
                @click.stop="handleDelete(null, image)"
              >
              </el-button>
            </el-card>
          </div>
        </div>
        <el-row>
          <el-col :span="24" class="list__pagination">
            <pagination
              layout="prev, pager, next"
              :current-page="page"
              :total-pages="Math.ceil(count / pageSize)"
              @page-change="handlePageChange"
            >
            </pagination>
          </el-col>
        </el-row>
        <el-row>
          <el-row>
            <el-col>
              <component-compiler
                v-for="(component, i) in footerActions()"
                :key="`list-action-${i}`"
                class="margin-right-15"
                :content="component"
              />
            </el-col>
          </el-row>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import { Catalog, Image } from '@/api'
import ListMixin from '@/crudBases/list/ListMixin'

export default {
  mixins: [ListMixin],
  data() {
    return {
      editRouteName: 'Edit Image',
      addRoute: { name: 'Add Image' },
      verboseName: 'Zdjęcie',
      verboseNamePlural: 'Zdjęcia',
      listFields: [
        { label: 'zdjecie', type: 'image', prop: 'thumbnail.url' },
        { prop: 'title', label: 'tytuł' }
      ],
      catalogOptions: []
    }
  },
  computed: {
    service() {
      return Image
    }
  },
  mounted() {
    this.fetchCatalogs()
  },
  methods: {
    fetchCatalogs() {
      Catalog.fetch().then(res => {
        this.catalogOptions = res.data.results.map(item => ({ ...item }))
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.thumbnail {
  max-width: 100%;
  max-height: 100%;
  transition: 0.3s all;
  object-fit: cover;

  &__wrapper {
    overflow: hidden;
    text-align: center;
    height: 130px;
    margin-bottom: 10px;
  }

  &:hover {
    transform: scale(1.05);
  }
}

.remove-button {
  position: absolute;
  top: 0;
  right: 0;
}

.grid {
  display: grid;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  grid-template-columns: repeat(4, 1fr);
}

.search-items {
  display: flex;

  .catalog-search {
    max-width: 200px;
    margin-right: 10px;
  }
}
</style>
