<script>
import { Page } from '@/api'
import PageFormBase from '@/crudBases/form/PageFormBase'
import fields from '@/components/fields/'

export default {
  extends: PageFormBase,
  data() {
    return {
      redirectRoute: { name: 'Pages List' }
    }
  },
  computed: {
    service() {
      return Page
    }
  },
  methods: {
    getGeneralFields() {
      return [
        new fields.Input({
          label: 'Tytuł',
          modelPath: [this, 'form', 'title'],
          multilingual: true
        }),
        new fields.Switch({
          label: 'Ukryta',
          modelPath: [this, 'form', 'is_hidden']
        })
      ]
    }
  }
}
</script>
