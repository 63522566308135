<template>
  <div>
    <el-button-group class="button-group">
      <el-button type="default" @click="showModal()">
        + Dodaj scenę / miejsce
      </el-button>
    </el-button-group>
    <el-dialog
      title="Tworzenie nowego miejsca"
      append-to-body
      width="90vw"
      top="4vh"
      :visible.sync="modalVisible"
    >
      <place-form in-modal @submit="submitForm()" />
      <span slot="footer" class="dialog-footer">
        <el-button @click="hideModal()">Anuluj</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import PlaceForm from '../../Places/PlaceForm'

export default {
  components: { PlaceForm },
  data() {
    return {
      modalVisible: false
    }
  },
  methods: {
    showModal() {
      this.modalVisible = true
    },
    hideModal() {
      this.modalVisible = false
    },
    submitForm() {
      this.hideModal()
      this.$emit('reload')
    }
  }
}
</script>
<style lang="scss" scoped>
.button-group {
  display: flex;
  justify-content: flex-end;
}
</style>
