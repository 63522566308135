<script>
import BaseWidgetForm from './BaseWidgetForm'
import fields from '@/components/fields/'
import { get } from 'lodash'

export default {
  extends: BaseWidgetForm,
  data() {
    return {
      arrayFields: ['items']
    }
  },
  computed: {
    tabsFieldsSchema() {
      return [
        {
          label: 'Ogólne',
          fields: [
            ...this.getCommonFields(),
            new fields.Input({
              label: 'Nagłówek',
              modelPath: [this, 'form', 'title'],
              required: true,
              multilingual: true
            }),
            new fields.Formset({
              modelPath: [this, 'form', 'items'],
              items: this.form.items.map((item, i) => ({
                headerLabel: get(item, ['title_pl']),
                fields: [
                  new fields.Input({
                    label: 'Tytuł',
                    modelPath: [this, 'form', 'items', i, 'title'],
                    multilingual: true,
                    required: true
                  }),
                  new fields.Input({
                    label: 'Link do facebooka',
                    modelPath: [this, 'form', 'items', i, 'facebook_url']
                  }),
                  new fields.Input({
                    label: 'Link do twittera',
                    modelPath: [this, 'form', 'items', i, 'twitter_url']
                  }),
                  new fields.Input({
                    label: 'Link do instagrama',
                    modelPath: [this, 'form', 'items', i, 'instagram_url']
                  })
                ]
              }))
            })
          ]
        }
      ]
    }
  }
}
</script>
