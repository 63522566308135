<template>
  <div>
    <el-form :inline="true" @submit.prevent.native="submitSearch">
      <el-form-item class="list__search-wrapper">
        <el-input
          v-model="search"
          class="list__search"
          clearable=""
          placeholder="Nazwa"
          @input="submitSearch"
        />
      </el-form-item>
      <el-form-item class="list__search-wrapper">
        <el-button @click="submitSearch">{{ $t('search') }}</el-button>
      </el-form-item>
      <el-form-item class="list__search-wrapper">
        <div class="catalog-search">
          <el-select
            v-model="catalog"
            filterable
            clearable
            placeholder="Wybierz katalog"
            @change="submitSearch"
          >
            <el-option
              v-for="item in catalogOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
      </el-form-item>
    </el-form>
    <div v-loading="loading" class="list">
      <el-card
        v-for="image in images"
        :key="`${image.id}`"
        shadow="hover"
        :body-style="{ padding: '0px' }"
        class="item"
      >
        <div @click="submitImage(image.id)">
          <img
            class="item__img"
            :src="(image.thumbnail && image.thumbnail.url) || image.file_url"
            alt=""
          />
          <p v-if="image.title" class="item__text">
            {{ truncate(image.title) }}
          </p>
        </div>
      </el-card>
    </div>

    <el-col :span="24" class="list__pagination">
      <el-pagination
        layout="prev, pager, next"
        :disabled="loading"
        :page-size="pageSize"
        :total="count"
        @current-change="handlePageChange"
      >
      </el-pagination>
    </el-col>
  </div>
</template>

<script>
import { Catalog, Image } from '@/api'
import { debounce } from 'lodash'

export default {
  props: {
    extensions: {
      type: Array
    }
  },
  data() {
    return {
      images: [],
      page: 1,
      search: '',
      pageSize: 10,
      count: 0,
      loading: false,
      catalogOptions: [],
      catalog: ''
    }
  },
  watch: {
    dialogFormVisible(value) {
      if (value) {
        this.resetFilters()
        this.fetchData()
      }
    }
  },
  mounted() {
    this.fetchData()
    this.fetchCatalogs()
  },
  methods: {
    submitSearch() {
      this.page = 1
      this.debouncedFetchData()
    },
    resetFilters() {
      this.search = ''
      this.catalog = ''
      this.page = 1
    },
    debouncedFetchData: debounce(function () {
      this.fetchData()
    }, 300),
    fetchData() {
      this.loading = true

      const params = {
        search: this.search,
        catalog: this.catalog,
        page: this.page
      }

      if (this.extensions && this.extensions.length) {
        params.extension = this.extensions
      }

      this.images = []

      Image.fetch({}, { params })
        .then(response => {
          this.images = response.data.results
          this.pageSize = response.data.page_size
          this.count = response.data.count
        })
        .finally(() => {
          this.loading = false
        })
    },
    submitImage(image) {
      this.$emit('submit', image)
    },
    handlePageChange(value) {
      this.page = value
      this.fetchData()
    },
    truncate(text) {
      if (text) {
        return text.slice(0, 20)
      }
      return ''
    },
    fetchCatalogs() {
      Catalog.fetch().then(res => {
        this.catalogOptions = res.data.results.map(item => ({ ...item }))
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.list {
  grid-gap: 20px 20px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
}

.item {
  cursor: pointer;
  width: 150px;
  text-align: center;
  display: grid;
  align-items: center;
  transition: all 0.3s;

  &__img {
    max-width: 100%;
  }

  &__text {
    margin: 0;
    color: #999;
    font-size: 13px;
  }

  &:hover {
    transform: scale(1.05);
    border-color: #a9a9a9;
  }
}

.catalog-search {
  margin-left: 10px;
}
</style>
