<template>
  <div class="card">
    <span class="card-header">
      <img class="card-icon" :src="iconUrl" alt="" />
      {{ header }}
    </span>
    <el-container
      v-if="loaderEnabled && items === undefined"
      v-loading="true"
      class="loader"
    />
    <ul v-if="items" class="card-items">
      <li v-for="(item, index) of items" :key="index" class="card-item">
        <router-link tag="el-link" :to="getLink(item)">
          {{ item[titleKwarg] }}
        </router-link>
      </li>
    </ul>
    <ul class="card-static-items">
      <li v-if="showListItem" class="card-static-item">
        <router-link class="page-link" tag="el-link" :to="getListLink()">
          {{ listItemName }}
        </router-link>
      </li>
      <li v-if="showAddItem" class="card-static-item">
        <router-link class="page-link" tag="el-link" :to="getAddLink()">
          {{ addItemName }}
        </router-link>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  props: {
    header: {
      type: String,
      required: true
    },
    iconUrl: {
      type: String,
      default: '/icons/icon-kategoria.png'
    },
    titleKwarg: {
      type: String,
      required: false
    },
    lookupKwarg: {
      type: String,
      required: false,
      default: 'id'
    },
    items: {
      type: Array,
      required: false
    },
    addRouteName: {
      type: String,
      required: false
    },
    editRouteName: {
      type: String,
      required: false
    },
    addItemName: {
      type: String,
      required: false,
      default: '+ Dodaj'
    },
    listRouteName: {
      type: String,
      required: false
    },
    listItemName: {
      type: String,
      required: false,
      default: 'Przejdź do listy'
    },
    loaderEnabled: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    showListItem() {
      return !!this.listRouteName
    },
    showAddItem() {
      return !!this.addRouteName
    }
  },
  methods: {
    getLink(row) {
      return {
        name: this.editRouteName,
        params: {
          id: row[this.lookupKwarg]
        }
      }
    },
    getAddLink() {
      return {
        name: this.addRouteName
      }
    },
    getListLink() {
      return {
        name: this.listRouteName
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
