<script>
import { Newsletter } from '@/api'
import FormMixin from '@/crudBases/form/FormMixin'
import fields from '@/components/fields/'

export default {
  mixins: [FormMixin],
  data() {
    return {
      singleton: true,
      redirectRoute: null
    }
  },
  computed: {
    service() {
      return Newsletter
    }
  },
  methods: {
    getFields() {
      return [
        new fields.WYSIWYG({
          label: 'Informacja',
          modelPath: [this, 'form', 'info'],
          multilingual: true,
          required: true
        }),
        new fields.WYSIWYG({
          label: 'Podsumowanie',
          modelPath: [this, 'form', 'summary'],
          multilingual: true,
          required: true
        }),
        new fields.Input({
          label: 'Tekst podpowiedzi',
          modelPath: [this, 'form', 'input_placeholder'],
          multilingual: true,
          required: true
        }),
        new fields.Input({
          label: 'Treść pierwszej zgody',
          type: 'textarea',
          modelPath: [this, 'form', 'first_agreement'],
          multilingual: true
        }),
        new fields.Input({
          label: 'Treść drugiej zgody',
          type: 'textarea',
          modelPath: [this, 'form', 'second_agreement'],
          multilingual: true
        }),
        new fields.Input({
          label: 'Treść trzeciej zgody',
          type: 'textarea',
          modelPath: [this, 'form', 'third_agreement'],
          multilingual: true
        })
      ]
    }
  }
}
</script>
