<script>
import BaseWidgetForm from './BaseWidgetForm'
import fields from '@/components/fields/'

export default {
  extends: BaseWidgetForm,
  data() {
    return {
      arrayFields: ['items']
    }
  },
  computed: {
    tabsFieldsSchema() {
      return [
        {
          label: 'Ogólne',
          fields: [
            ...this.getCommonFields(),
            new fields.Input({
              label: 'Nagłówek',
              modelPath: [this, 'form', 'header'],
              multilingual: true
            }),
            new fields.Formset({
              modelPath: [this, 'form', 'items'],
              items: this.form.items.map((item, i) => ({
                headerLabel: `Element nr ${i + 1}`,
                fields: [
                  new fields.Image({
                    label: 'Grafika',
                    required: true,
                    modelPath: [this, 'form', 'items', i, 'image'],
                    multilingual: true
                  }),
                  new fields.Link({
                    label: 'Link',
                    required: true,
                    modelPath: [this, 'form', 'items', i, 'link']
                  })
                ]
              }))
            })
          ]
        }
      ]
    }
  }
}
</script>
