<template>
  <div>
    <h4>News'y (pl):</h4>
    <div style="padding-left: 50px">
      <el-collapse accordion>
        <el-collapse-item
          v-for="(item, index) in plNews"
          :key="index"
          :title="item.title"
          :name="index"
        >
          <div>
            Adres URL:
            <a :href="item.link" target="_blank">{{ item.link }}</a>
          </div>
          <div>
            Zdjęcie:
            <a :href="item.image" target="_blank">{{ item.image }}</a>
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>
    <h4>News'y (en):</h4>
    <div style="padding-left: 50px">
      <el-collapse accordion>
        <el-collapse-item
          v-for="(item, index) in enNews"
          :key="index"
          :title="item.title"
          :name="index"
        >
          <div>
            Adres URL:
            <a :href="item.link" target="_blank">{{ item.link }}</a>
          </div>
          <div>
            Zdjęcie:
            <a :href="item.image" target="_blank">{{ item.image }}</a>
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>
  </div>
</template>
<script>
import { Widget } from '@/api'

export default {
  data() {
    return {
      availableNews: {
        pl: [],
        en: []
      }
    }
  },
  computed: {
    plNews() {
      return this.availableNews.pl.slice(0, 5)
    },
    enNews() {
      return this.availableNews.en.slice(0, 5)
    }
  },
  created() {
    Widget.newsWidgetAvailableNews().then(response => {
      this.availableNews = response.data
    })
  }
}
</script>
