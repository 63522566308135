<template>
  <div>
    <el-card class="box-card">
      <div slot="header">
        <div class="wrapper" @click="collapsed = !collapsed">
          <div class="header" :class="{ 'header--errors': hasErrors }">
            <p class="header__text">
              <i
                class="arrow el-icon-arrow-down"
                :class="{ 'arrow--rotated': collapsed }"
              />
              {{ header }}
            </p>
          </div>
          <div class="buttons">
            <div class="buttons__order">
              <el-button
                v-if="!firstItem"
                type="text"
                title="Przenieś w górę"
                class="order-buttons"
                size="small"
                icon="fa fa-arrow-up"
                @click.stop="moveUp"
              />
              <el-button
                v-if="!lastItem"
                type="text"
                title="Przenieś w dół"
                class="order-buttons"
                size="small"
                icon="fa fa-arrow-down"
                @click.stop="moveDown"
              />
            </div>
            <el-button
              type="danger"
              circle
              size="mini"
              icon="el-icon-close"
              @click.stop="removeItem"
            />
          </div>
        </div>
      </div>
      <el-collapse-transition name="el-fade-in-linear">
        <div v-if="!collapsed" class="fields">
          <slot></slot>
        </div>
      </el-collapse-transition>
    </el-card>
  </div>
</template>

<script>
export default {
  props: {
    items: Array,
    item: [Object, Number],
    headerLabel: String,
    hasErrors: Boolean
  },
  data() {
    return {
      collapsed: false
    }
  },
  computed: {
    header() {
      return this.headerLabel || 'Nowy element'
    },
    itemIndex() {
      return this.items.indexOf(this.item)
    },
    firstItem() {
      return this.itemIndex === 0
    },
    lastItem() {
      return this.itemIndex >= this.items.length - 1
    }
  },
  watch: {
    hasErrors(value) {
      if (value) {
        this.collapsed = false
      }
    }
  },
  methods: {
    removeItem() {
      this.items.splice(this.itemIndex, 1)
    },
    swapItem(from, to) {
      const tmp = this.items[to]
      this.$set(this.items, to, this.items[from])
      this.$set(this.items, from, tmp)
    },
    moveUp() {
      if (!this.firstItem) {
        this.swapItem(this.itemIndex, this.itemIndex - 1)
      }
    },
    moveDown() {
      if (!this.lastItem) {
        this.swapItem(this.itemIndex, this.itemIndex + 1)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.close-button {
  position: absolute;
  top: -10px;
  right: -10px;
}

.header {
  flex: 1;
  align-items: center;
  color: $text-color;

  &--errors {
    font-weight: 700;
    color: $error-color;
  }

  &__text {
    margin: 10px 0;
    font-size: 15px;
  }
}

.wrapper {
  display: flex;
  cursor: pointer;
}

.buttons {
  display: flex;
  align-items: center;

  &__order {
    margin-right: 10px;
  }
}

::v-deep .el-card__header {
  padding: 0 20px;
  background: #f9f9f9;
}

.order-buttons {
  color: $text-color;
}

.fields {
  padding: 20px;
}

::v-deep .el-card__body {
  padding: 0;
}

.arrow {
  transition: 0.3s all;
  &--rotated {
    transform: rotate(-90deg);
  }
}
</style>
