<template>
  <div>
    <widgets-dialogs
      ref="widgetDialogs"
      :widgets="form.widgets"
      :errors="errors.widgets"
    />
    <revisions-dialog ref="revisionsDialog" @submit="loadRevision" />
    <revisions-alert :pending-revision="pendingRevision" />
    <form-wrapper @submit="onSubmit">
      <div slot="box" v-loading="loading">
        <component-compiler
          v-for="(component, i) in getFields()"
          :key="`form-${i}`"
          :content="component"
        />
      </div>
      <form-nav slot="navbar" :loading="loading">
        <component-compiler
          v-for="(component, i) in getNavButtons()"
          :key="`nav-button-${i}`"
          :content="component"
        />
      </form-nav>
    </form-wrapper>
  </div>
</template>
<script>
import FormMixin from '@/crudBases/form/FormMixin'
import fields from '@/components/fields/'
import WidgetsDialogs from '@/components/widgets/WidgetsDialogs'
import RevisionsDialog from '@/components/revisions/RevisionsDialog'
import RevisionsMixin from '@/crudBases/form/RevisionsMixin'
import RevisionsAlert from '@/crudBases/form/RevisionsAlert'

export default {
  components: {
    WidgetsDialogs,
    RevisionsDialog,
    RevisionsAlert
  },
  extends: FormMixin,
  mixins: [RevisionsMixin],
  data() {
    return {
      selectedTab: 'general',
      form: {
        widgets: []
      }
    }
  },
  computed: {
    revisionsAvailable() {
      return this.lookupParams
    }
  },
  methods: {
    getNavButtons() {
      const buttons = [
        {
          component: 'router-link',
          attrs: {
            to: { ...this.redirectRoute, query: this.$route.query }
          },
          condition: this.redirectRoute,
          children: [
            {
              component: 'el-button',
              innerText: 'Anuluj'
            }
          ]
        }
      ]

      if (this.revisionsAvailable) {
        buttons.push(this.getRevisionsDropdown())
      } else {
        buttons.push({
          component: 'el-button',
          attrs: {
            type: 'primary'
          },
          listeners: {
            click: this.onSubmit
          },
          innerText: 'Zatwierdź'
        })
      }

      return buttons
    },
    getFields() {
      return [
        {
          component: 'el-tabs',
          attrs: {
            stretch: true,
            value: this.selectedTab
          },
          listeners: {
            input: value => (this.selectedTab = value)
          },
          children: [
            this.getGeneralTab(),
            this.getWidgetsTab()
            // this.getSEOTab()
          ]
        }
      ]
    },
    getGeneralTab() {
      return {
        component: 'el-tab-pane',
        attrs: {
          label: 'Ogólne',
          name: 'general'
        },
        children: this.getGeneralFields()
      }
    },
    getWidgetsTab() {
      return {
        component: 'el-tab-pane',
        attrs: {
          label: 'Widgety'
        },
        children: [new fields.Widgets({ modelPath: [this, 'form', 'widgets'] })]
      }
    },
    getGeneralFields() {
      return []
    },
    getSEOTab() {
      return {
        component: 'el-tab-pane',
        attrs: {
          label: 'SEO'
        },
        children: [
          new fields.Input({
            label: 'Slug',
            modelPath: [this, 'form', 'slug'],
            multilingual: true,
            condition: !!this.form.id
          }),
          new fields.Input({
            label: 'Tytuł dla facebooka',
            modelPath: [this, 'form', 'og_title'],
            multilingual: true
          }),
          new fields.Input({
            label: 'Opis dla facebooka',
            modelPath: [this, 'form', 'og_description'],
            type: 'textarea',
            multilingual: true
          }),
          new fields.Image({
            label: 'Zdjęcie dla facebooka',
            modelPath: [this, 'form', 'og_image']
          }),
          new fields.Input({
            label: 'Tytuł strony dla wyszukiwarki google',
            modelPath: [this, 'form', 'meta_title'],
            multilingual: true
          }),
          new fields.Input({
            label: 'Opis dla wyszukiwarki google',
            modelPath: [this, 'form', 'meta_description'],
            type: 'textarea',
            multilingual: true
          })
        ]
      }
    }
  }
}
</script>
