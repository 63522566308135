import FieldBase from './FieldBase'
import RemoteSelectInputBase from '@/components/inputs/RemoteSelectInputBase'

export default class extends FieldBase {
  constructor({
    service,
    multiple,
    sortable,
    allowRefresh,
    refreshButtonClassName,
    allowAddEdit,
    disabled,
    clearable,
    paginated,
    labelField,
    valueField,
    allowCreate,
    maxItems,
    help,
    ...args
  }) {
    super(args)
    this.children = [
      {
        ...this.getComponentWithVModel(RemoteSelectInputBase),
        props: {
          service,
          multiple,
          sortable,
          allowRefresh,
          refreshButtonClassName,
          allowAddEdit,
          disabled,
          clearable,
          paginated,
          labelField,
          valueField,
          allowCreate,
          maxItems
        }
      }
    ]
    if (help) {
      this.children.push({
        component: 'small',
        innerText: help,
        attrs: { class: 'field-info' }
      })
    }
  }
}
