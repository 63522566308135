<template>
  <div class="header">
    <div class="wrapper">
      <div class="title-wrapper">
        <h1 class="title" v-html="title" />
        <p v-if="subtitle" class="t-label subtitle" v-html="subtitle" />
      </div>
      <el-button
        v-if="addButton"
        type="primary"
        icon="plus"
        size="large"
        @click="$router.push(addButton.route)"
      >
        {{ addButton.label }}
      </el-button>
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    subtitle: {
      type: String,
      default: ''
    },
    addButton: {
      type: Object,
      required: false
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  height: 116px;
  line-height: 64px;
  padding: 0;
  margin-bottom: -16px;
  margin-top: 12px;
}

.title {
  font-size: 36px;
}

.wrapper {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title-wrapper {
  display: flex;
  flex-direction: column;
}

.subtitle {
  order: -1;
  line-height: 1;
  margin-bottom: -12px;
}
</style>
