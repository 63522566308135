<template>
  <div v-if="condition">
    <div v-if="nonFieldErrors" class="el-form-item is-error">
      {{ nonFieldErrors }}
    </div>
    <div class="formset-wrapper">
      <h2 v-if="header" v-text="header" />
      <slot></slot>
      <el-button
        v-if="!maxItems || items.length < maxItems"
        class="add-item-button"
        type="warning"
        @click="addItem"
      >
        Dodaj nowy
      </el-button>
    </div>
  </div>
</template>
<script>
import { clone } from 'lodash'

export default {
  props: {
    blankObject: Object,
    items: Array,
    header: String,
    errors: {
      type: [Array, String, Object],
      default: () => []
    },
    maxItems: Number,
    condition: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    nonFieldErrors() {
      if (typeof this.errors[0] === 'string') {
        return this.errors
      } else if (this.errors.non_field_errors) {
        return this.errors.non_field_errors.join(',')
      }
      return false
    }
  },
  methods: {
    addItem() {
      const newObject = clone(this.blankObject)
      if (!Array.isArray(this.items)) {
        this.$emit('input', [newObject])
      } else {
        this.items.push(newObject)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.add-item-button {
  margin-top: 15px;
}

.is-error {
  color: $error-color;
}
</style>

<style>
.formset-wrapper .formset-wrapper {
  margin-left: 100px;
}
</style>
