import FieldBase from './FieldBase'

export default class extends FieldBase {
  constructor({
    hideLinkLabel,
    labelPlaceholder,
    internalOnly,
    help,
    showIconInput,
    ...args
  }) {
    super(args)
    this.attrs.error = ''
    this.children = [
      {
        ...this.getComponentWithVModel('link-input'),
        props: {
          hideLinkLabel,
          labelPlaceholder,
          internalOnly,
          showIconInput,
          errors: this.getErrorsForPath(this.modelPath)
        }
      }
    ]
    if (help) {
      this.children.push({
        component: 'el-alert',
        attrs: {
          showIcon: true,
          closable: false,
          title: help
        }
      })
    }
  }
}
