<!--
<template>
  <el-form :form="form" @submit.prevent="onSubmit">
    <el-form-item label="email" v-bind="errorsBind('email')">
      <el-input
        type="text"
        v-model="form.email"
        auto-complete="off"
        placeholder="adres email"
      >
      </el-input>
    </el-form-item>
    <el-form-item>
      <el-button type="primary" html-type="submit">
        <span>Login</span>
      </el-button>
    </el-form-item>
  </el-form>
</template>
-->

<script>
import { get } from 'lodash'

export default {
  props: {
    value: { type: [Object, Array], required: false },
    errors: { type: [Object, Array, String], required: true },
    isDirty: { type: Boolean, default: true }
  },
  computed: {
    form: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    },
    errorsBind() {
      return (...field) => {
        const errors = field.length > 0 ? get(this.errors, field) : this.errors
        const text = Array.isArray(errors) ? errors.join(',') : errors
        if (field[0] === 'non_field_errors' && !text) {
          return { style: 'display: none;' }
        }
        return {
          'show-message': !!errors,
          error: text ? text : ''
        }
      }
    }
  },
  methods: {
    onSubmit() {
      this.$emit('submit')
    },
    removeFormsetItem(item) {
      const index = this.form.indexOf(item)
      if (index > -1) {
        this.form.splice(index, 1)
      }
    },
    addFormsetItem() {
      // use if you make formset
      const blankObject = this.getBlankObject()
      if (this.form === undefined) {
        this.form = [blankObject]
      } else {
        this.form = [...this.form, blankObject]
      }
    },
    getBlankObject() {
      return {}
    }
  }
}
</script>
