<template>
  <el-dialog
    title="Szczegóły widgetu"
    :before-close="handleClose"
    width="80vw"
    :visible.sync="dialogFormVisible"
  >
    <widget-form
      v-if="widget"
      :widget="widget"
      :errors-from-form="errors"
      @submit="submitForm"
      @cancel="cancelForm"
    />
  </el-dialog>
</template>
<script>
import { isEqual } from 'lodash'
import WidgetForm from './Form'

export default {
  components: {
    WidgetForm
  },
  data() {
    return {
      widget: null,
      errors: {},
      dialogFormVisible: false
    }
  },
  watch: {
    dialogFormVisible(value) {
      if (!value) {
        this.widget = null
      }
    }
  },
  methods: {
    openDialog(widget, errors) {
      this.widget = widget
      this.errors = errors
      this.dialogFormVisible = true
    },
    handleClose(done) {
      const formWasChanged = !isEqual(this.formCopy, this.originalForm)
      if (formWasChanged) {
        this.$confirm('Zmiany zostaną utracone.')
          .then(() => {
            done()
          })
          .catch(() => {})
      } else {
        done()
      }
    },
    cancelForm() {
      this.dialogFormVisible = false
      this.$emit('cancel')
    },
    submitForm(form) {
      this.dialogFormVisible = false
      this.$emit('submit', form, this.widget)
    }
  }
}
</script>
