import FieldBase from './FieldBase'

export default class extends FieldBase {
  constructor({
    type = 'date',
    size,
    format = 'yyyy-MM-dd HH:mm:ss',
    clearable = false,
    valueFormat,
    disabled,
    help,
    ...args
  }) {
    super(args)
    const child = this.getComponentWithVModel('el-date-picker')
    child.attrs = {
      ...child.attrs,
      type,
      size,
      format,
      clearable,
      valueFormat,
      disabled
    }
    this.children = [child]
    if (help) {
      this.children.push({
        component: 'p',
        innerText: help,
        attrs: { class: 'field-info' }
      })
    }
  }
}
