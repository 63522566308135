<template>
  <div>
    <el-input v-model="searchFilter" placeholder="Widget..." />

    <el-row
      v-for="(globalWidgets, widgetLabel) in groupedWidgets"
      :key="widgetLabel"
      :gutter="12"
    >
      <el-col :span="24">
        <h3>{{ widgetLabel }}</h3>
      </el-col>
      <transition-group tag="div" name="el-fade-in-linear">
        <el-col v-for="widget in globalWidgets" :key="widget.id" :span="8">
          <el-card
            :key="widget.polymorphic_ctype"
            shadow="hover"
            class="type"
            @click.native="
              !alreadyUsedWidgetIds.includes(widget.id)
                ? selectWidget(widget)
                : widgetAlreadyAdded(widget)
            "
          >
            <img class="thumbnail" :src="getImageForType(widget)" alt="" />
            {{ widget.working_name }}
            <p
              v-if="alreadyUsedWidgetIds.includes(widget.id)"
              style="color: #f56c6c"
            >
              Widget jest już dodany.
              <!--
               @TODO ask Iwona/Ewa about error message when widget is already added
               -->
            </p>
          </el-card>
        </el-col>
      </transition-group>
    </el-row>
  </div>
</template>
<script>
import { groupBy, orderBy } from 'lodash'

export default {
  props: {
    widgets: Array
  },
  data() {
    return {
      searchFilter: '',
      step: 1,
      dialogFormVisible: false,
      form: null
    }
  },
  computed: {
    alreadyUsedWidgetIds() {
      return this.widgets.map(widget => widget.id)
    },
    filteredWidgetTypes() {
      const query = this.searchFilter.toLowerCase()
      return this.$store.state.globalWidgets.filter(widget =>
        widget.working_name.toLowerCase().includes(query)
      )
    },
    groupedWidgets() {
      const sortedTypes = orderBy(
        this.filteredWidgetTypes,
        ['widget_label'],
        ['desc']
      )
      return groupBy(sortedTypes, 'widget_label')
    }
  },
  methods: {
    selectWidget(widget) {
      this.searchFilter = ''
      this.$emit('widgetSelected', widget)
    },
    widgetAlreadyAdded(widget) {
      this.$message({
        message: `Widget ${widget.working_name} jest już dodany.`,
        type: 'error'
      })
    },
    getImageForType(type) {
      return `/widgets/icons/${type.resourcetype}.png`
    }
  }
}
</script>
<style lang="scss" scoped>
.type {
  cursor: pointer;
}

.el-card {
  margin-bottom: 18px;
}

.thumbnail {
  height: 100px;
  width: auto;
  max-width: 100%;
  object-fit: contain;
  display: block;
  margin-bottom: 15px;
}
</style>
