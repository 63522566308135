<script>
import BaseWidgetForm from './BaseWidgetForm'
import fields from '@/components/fields/'

export default {
  extends: BaseWidgetForm,
  computed: {
    tabsFieldsSchema() {
      return [
        {
          label: 'Ogólne',
          fields: [
            ...this.getCommonFields(),
            new fields.Input({
              label: 'Tytuł',
              modelPath: [this, 'form', 'title'],
              required: false,
              multilingual: true
            }),
            new fields.Image({
              label: 'Zdjęcie',
              modelPath: [this, 'form', 'image'],
              required: true,
              multilingual: true
            }),
            new fields.Link({
              label: 'Link',
              required: false,
              modelPath: [this, 'form', 'link']
            })
          ]
        }
      ]
    }
  }
}
</script>
