<template>
  <div>
    <el-drawer
      title="Wybierz wersję"
      append-to-body
      class="images-dialog"
      :visible.sync="dialogFormVisible"
    >
      <div>
        <div v-for="revision in revisions" :key="revision.id" class="revision">
          <p class="revision__timestamp">
            {{ getTimestampFormatted(revision.created) }}
          </p>
          <p v-if="revision.is_current">
            <i v-if="revision.is_current" class="fa fa-check"></i> obecna wersja
            robocza
          </p>
          <p v-if="revision.is_live" class="revision--current">
            <i v-if="revision.is_live" class="fa fa-check"></i> obecna wersja
          </p>
          <el-button
            v-if="revision.preview_url"
            size="small"
            target="_blank"
            :href="revision.preview_url"
            >podgląd
          </el-button>
          <el-button size="small" @click="e => submitForm(revision)"
            >wczytaj do formularza
          </el-button>
          <el-divider />
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelForm()">Anuluj</el-button>
      </span>
    </el-drawer>
  </div>
</template>
<script>
import { Revision } from '@/api'
import moment from 'moment'

export default {
  components: {},
  data() {
    return {
      dialogFormVisible: false,
      revisions: []
    }
  },
  methods: {
    getTimestampFormatted(timestamp) {
      return moment(String(timestamp)).format('DD/MM/YYYY HH:mm:ss')
    },
    submitForm(revision) {
      this.$emit('submit', revision)
      this.dialogFormVisible = false
    },
    openDialog(page) {
      this.dialogFormVisible = true
      Revision.fetch(
        {},
        {
          params: {
            page
          }
        }
      ).then(res => {
        this.revisions = res.data
      })
    },
    cancelForm() {
      this.dialogFormVisible = false
      this.$emit('cancel')
    },
    uploadError() {
      let message = 'Wystąpił problem podczas wgrywania wersji roboczej.'
      this.$message({
        message: message,
        type: 'error'
      })
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .el-drawer__body {
  padding: 20px;
  overflow-y: auto;
  -ms-overflow-style: none; // IE 10+
  scrollbar-width: none; // Firefox
  ::-webkit-scrollbar {
    display: none; // Safari and Chrome
  }
}

.revision {
  &__timestamp {
    font-size: 16px;
    font-weight: 300;
    margin-bottom: 0;
  }

  &--current {
    color: $success-color;
  }
}
</style>
