import FieldBase from './FieldBase'

export default class extends FieldBase {
  constructor({ extensions, help, ...args }) {
    super(args)
    this.children = [
      {
        ...this.getComponentWithVModel('image-input'),
        props: { help, extensions }
      }
    ]
  }
}
