<template>
  <el-pagination
    background
    :total="paginationData.total"
    :current-page="paginationData.current"
    :page-size="paginationData.pageSize"
    :hide-on-single-page="paginationData.hideOnSinglePage"
    layout="prev, pager, next"
    @current-change="paginationData.onChange"
  />
</template>

<script>
export default {
  props: {
    paginationData: Object
  }
}
</script>
