<script>
import { Catalog } from '@/api'
import fields from '@/components/fields/'
import FormMixin from '@/crudBases/form/FormMixin'

export default {
  mixins: [FormMixin],
  data() {
    return {
      redirectRoute: { name: 'Catalog List' }
    }
  },
  computed: {
    service() {
      return Catalog
    }
  },
  methods: {
    getFields() {
      return [
        new fields.Input({
          label: 'Nazwa',
          required: true,
          modelPath: [this, 'form', 'name']
        })
      ]
    }
  }
}
</script>
