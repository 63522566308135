<template>
  <div>
    <el-dialog
      title="Dodaj nowy widget"
      width="80vw"
      :visible.sync="dialogFormVisible"
    >
      <transition name="slide-left">
        <select-widget-type
          v-if="step === 1"
          @widgetSelected="widgetSelected"
        />
        <div v-else-if="step === 2">
          <widget-form
            v-if="form"
            :widget="form"
            @submit="addWidget"
            @cancel="cancelForm"
          />
        </div>
      </transition>
    </el-dialog>
  </div>
</template>
<script>
import { groupBy, orderBy } from 'lodash'
import WidgetForm from '@/components/widgets/widgetFormDialog/Form'
import SelectWidgetType from './SelectWidgetType'

export default {
  components: {
    SelectWidgetType,
    WidgetForm
  },
  data() {
    return {
      step: 1,
      dialogFormVisible: false,
      index: 0,
      form: null
    }
  },
  computed: {
    groupedWidgets() {
      const sortedTypes = orderBy(
        this.$store.state.widgetTypes,
        ['group'],
        ['desc']
      )
      return groupBy(sortedTypes, 'group')
    }
  },
  methods: {
    widgetSelected(resourcetype) {
      this.step = 2
      this.form = { resourcetype }
    },
    openDialog(index) {
      this.dialogFormVisible = true
      this.index = index
      this.step = 1
    },
    cancelForm() {
      this.dialogFormVisible = false
      this.step = 1
      this.$emit('cancel')
    },
    addWidget(form) {
      this.$emit('addWidget', form, this.index)
      this.step = 1
      this.dialogFormVisible = false
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .el-dialog {
  overflow: hidden;
}

// pages transitions
.slide-right-leave-active,
.slide-right-enter-active,
.slide-left-leave-active,
.slide-left-enter-active {
  transition: transform 0.5s;
}

.slide-left-leave-active {
  position: absolute;
}

.slide-right-enter {
  -webkit-transform-origin: 50% 50%;
  transform: translate3d(-100%, 0, 0);
}

.slide-right-leave-to {
  transform-origin: 50% 50%;
  transform: translate3d(100%, 0, 0);
}

.slide-left-enter {
  transform-origin: 50% 50%;
  transform: translate3d(100%, 0, 0);
}

.slide-left-leave-to {
  transform-origin: 50% 50%;
  transform: translate3d(-100%, 0, 0);
}
</style>
