import FieldBase from './FieldBase'

export default class extends FieldBase {
  constructor({ type, ...args }) {
    super(args)
    const component = this.getComponentWithVModel('wysiwyg-input')
    component.attrs.type = type
    this.children = [component]
  }
}
