<template>
  <div>
    <page-header :title="header.title" :add-button="header.addButton" />
    <filters />
    <div class="box">
      <inner-list v-loading="loading" :results="results" />
      <div class="list__pagination">
        <pagination :pagination-data="pagination" />
      </div>
    </div>
  </div>
</template>
<script>
import { User } from '@/api'
import InnerList from './InnerList'
import Filters from './Filters'
import PaginatedList from '@/layouts/PaginatedList'
import PageHeader from '@/layouts/PageHeader'
import Pagination from '@/layouts/Pagination'

export default {
  components: {
    InnerList,
    Filters,
    PageHeader,
    Pagination
  },
  extends: PaginatedList,
  data() {
    return {
      service: User
    }
  },
  computed: {
    header() {
      let title = 'Użytkownicy'
      if (this.pagination.total) {
        title = `${title} <sup>(${this.pagination.total})</sup>`
      }

      const addButton = this.$store.getters.hasPerm('users.add_user')
        ? {
            label: 'Dodaj użytkownika',
            route: { name: 'Add User' }
          }
        : null

      return {
        addButton,
        title
      }
    }
  },
  created() {
    this.$store.dispatch('fetchProfiles')
  }
}
</script>
