<template>
  <div>
    <el-dialog
      title="Wybierz globalny widget"
      width="80vw"
      :visible.sync="dialogFormVisible"
    >
      <transition name="slide-left">
        <select-global-widget
          v-if="step === 1"
          :widgets="widgets"
          @widgetSelected="widgetSelected"
        />
        <div v-else-if="step === 2">
          <widget-form
            v-if="form"
            :widget="form"
            @submit="addWidget"
            @cancel="cancelForm"
          />
        </div>
      </transition>
    </el-dialog>
  </div>
</template>
<script>
import WidgetForm from '@/components/widgets/widgetFormDialog/Form'
import SelectGlobalWidget from './SelectGlobalWidget'

export default {
  components: {
    SelectGlobalWidget,
    WidgetForm
  },
  props: {
    widgets: Array
  },
  data() {
    return {
      step: 1,
      dialogFormVisible: false,
      index: 0,
      form: null
    }
  },
  methods: {
    widgetSelected(widget) {
      this.step = 2
      this.form = { ...widget }
    },
    openDialog(index) {
      this.dialogFormVisible = true
      this.index = index
      this.step = 1
    },
    cancelForm() {
      this.dialogFormVisible = false
      this.step = 1
      this.$emit('cancel')
    },
    addWidget(form) {
      this.$emit('addWidget', form, this.index)
      this.step = 1
      this.dialogFormVisible = false
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .el-dialog {
  overflow: hidden;
}

// pages transitions
.slide-right-leave-active,
.slide-right-enter-active,
.slide-left-leave-active,
.slide-left-enter-active {
  transition: transform 0.5s;
}

.slide-left-leave-active {
  position: absolute;
}

.slide-right-enter {
  -webkit-transform-origin: 50% 50%;
  transform: translate3d(-100%, 0, 0);
}

.slide-right-leave-to {
  transform-origin: 50% 50%;
  transform: translate3d(100%, 0, 0);
}

.slide-left-enter {
  transform-origin: 50% 50%;
  transform: translate3d(100%, 0, 0);
}

.slide-left-leave-to {
  transform-origin: 50% 50%;
  transform: translate3d(-100%, 0, 0);
}
</style>
