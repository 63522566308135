<script>
import { ScheduledNotification } from '@/api'
import ListMixin from '@/crudBases/list/ListMixin'

export default {
  mixins: [ListMixin],
  data() {
    return {
      verboseName: 'Statystyki powiadomień',
      verboseNamePlural: 'Statystyki powiadomień',
      showActions: false,
      fullWidth: true,
      listFields: [
        { prop: 'id', label: 'id', width: 80 },
        { label: 'Data wysyłki', formatter: this.formatDate('sent_at') },
        { prop: 'title', label: 'Tytuł powiadomienia' },
        { prop: 'body', label: 'Treść' },
        { prop: 'language', label: 'Język' },
        { prop: 'messages_sent', label: 'Ilość wysłanych pushy' }
      ]
    }
  },
  computed: {
    service() {
      return ScheduledNotification
    }
  },
  methods: {
    fetchData() {
      this.listLoading = true
      let params = {
        page: this.page,
        ...this.filters.selected
      }
      this.service
        .statistics({}, { params })
        .then(response => {
          this.count = response.data.count
          this.pageSize = response.data.page_size
          this.items = response.data.results
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.$message({
              message: 'Nie masz uprawnien do danych zasobów',
              type: 'error'
            })
          }
        })
        .finally(() => {
          this.listLoading = false
        })
    }
  }
}
</script>
