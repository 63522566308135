<template>
  <div>
    <el-table
      :data="results"
      class="table"
      @row-click="item => clickTableRow(item)"
    >
      <el-table-column prop="id" label="ID" width="60" />
      <el-table-column prop="full_name" label="Imię i nazwisko" />
      <el-table-column prop="phone" label="Numer telefonu" />
      <el-table-column prop="is_active" label="Jest aktywny">
        <template slot-scope="scope">
          <i
            v-if="scope.row.is_active"
            class="icon el-icon-circle-check true"
            title="Tak"
          />
          <i v-else class="icon el-icon-circle-close false" title="Nie" />
        </template>
      </el-table-column>
      <el-table-column label="Akcje" align="right">
        <template slot-scope="scope">
          <router-link
            v-if="hasPermissionToChangeUser"
            :to="{ name: 'Edit User', params: { id: scope.row.uuid } }"
          >
            <el-button size="mini" icon="el-icon-edit" />
          </router-link>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
export default {
  props: {
    results: Array
  },
  computed: {
    hasPermissionToChangeUser() {
      return this.$store.getters.hasPerm('users.change_user')
    }
  },
  methods: {
    clickTableRow(item) {
      this.hasPermissionToChangeUser &&
        this.$router.push({ name: 'Edit User', params: { id: item.uuid } })
    }
  }
}
</script>
<style scoped>
.table {
  margin-bottom: 25px;
}

.table >>> .el-table__row {
  cursor: pointer;
}
</style>
