import axios from 'axios'
import Service, { prepareURL } from '@/api/service'
import getFormDataFromJSON from '@/common/js/formdata'

axios.defaults.baseURL = process.env.VUE_APP_API_URL

/*
    using '...Service('/url/:id/')' in object provides methods:
        fetch
        get
        delete
        put
        save
 */

export const SiteSettings = {
  update: payload => {
    return axios.put('/admin/core/settings/', payload)
  },
  get: payload => {
    return axios.get('/admin/core/settings/', payload)
  }
}

export const Attachment = {
  ...Service('/admin/attachments/:id/'),
  upload: payload => {
    return axios.post('/admin/attachments/', payload, {
      headers: { 'content-type': 'multipart/form-data' }
    })
  }
}

export const Page = {
  ...Service('/admin/pages/:id/'),
  revision(params, payload) {
    const url = prepareURL(`/admin/pages/:id/revision/`, params)
    return axios.post(url, payload)
  },
  preview(urlParams, payload) {
    const url = '/admin/pages/:id/preview/'
    return axios.post(prepareURL(url, urlParams), payload)
  }
}

export const Revision = Service('/admin/revisions/:id/')

export const Article = {
  ...Service('/admin/articles/:id/'),
  preview(urlParams, payload) {
    const url = '/admin/articles/:id/preview/'
    return axios.post(prepareURL(url, urlParams), payload)
  },
  revision(params, payload) {
    const url = prepareURL(`/admin/articles/:id/revision/`, params)
    return axios.post(url, payload)
  }
}

export const ArticleTag = {
  ...Service('/admin/articles/tags/:id/')
}

export const Widget = {
  ...Service('/admin/widgets/:id/'),
  types(params) {
    return axios.get('/widgets/admin/types/', { params })
  },
  validate(payload) {
    return axios.post('/widgets/admin/validate/', payload)
  },
  available(params) {
    return axios.get('/widgets/admin/available/', { params })
  },
  preview(payload) {
    return axios.post('/widgets/admin/preview/', payload)
  },
  global(params) {
    return axios.get('/admin/widgets/global/', { params })
  },
  imageWidgetIconChoices(params) {
    return axios.get('/admin/widgets/image-widget-icon-choices/', { params })
  },
  newsWidgetAvailableNews(params) {
    return axios.get('/admin/widgets/news-widget-available-news/', { params })
  }
}

export const MainMenu = {
  ...Service('/admin/menu/main-menu/')
}

export const Link = {
  availableLinkOptions() {
    return axios.get('/admin/links/available-options/')
  },
  availableWidgets(params) {
    return axios.get('/admin/links/available-widgets/', { params })
  }
}

export const Auth = {
  login(payload) {
    return axios.post('/public/users/auth/login/', payload)
  },
  sendSMSCode(payload) {
    return axios.post('/public/sms-auth/login/send-code/', payload)
  }
}

export const Image = {
  ...Service('/admin/images/:id/'),
  save: (payload, onUploadProgress) => {
    const formData = getFormDataFromJSON(payload)
    return axios.post('/admin/images/', formData, {
      headers: {
        'content-type': 'multipart/form-data'
      },
      onUploadProgress
    })
  },
  update: (urlParams, payload) => {
    const formData = getFormDataFromJSON(payload)
    const defaultUpdate = Service('/admin/images/:id/')['update']
    return defaultUpdate(urlParams, formData, {
      'content-type': 'multipart/form-data'
    })
  }
}

export const User = {
  ...Service('/admin/users/:id/'),
  fetchUserProfiles() {
    const url = '/admin/users/profiles/'
    return axios.get(url)
  },
  me() {
    const url = '/admin/users/me/'
    return axios.get(url)
  }
}

export const Dashboard = {
  getRecentPages() {
    return axios.get('/admin/dashboard/recent-pages/')
  },
  getRecentChangesInModels() {
    return axios.get('/admin/dashboard/recent-changes-in-models/')
  }
}

export const Event = {
  ...Service('/admin/events/:id/'),
  preview(urlParams, payload) {
    const url = '/admin/events/:id/preview/'
    return axios.post(prepareURL(url, urlParams), payload)
  }
}

export const EventCategory = {
  ...Service('/admin/events/categories/:id/')
}

export const EventActivityType = {
  ...Service('/admin/events/activity-types/:id/')
}

export const Place = {
  ...Service('/admin/places/:id/')
}

export const DefaultEventTicket = {
  ...Service('/admin/events/default-tickets/')
}

export const Ticket = {
  availableTypes() {
    return axios.get('/admin/tickets/available-types/')
  }
}

export const Catalog = {
  ...Service('/admin/images/catalogs/:id/')
}

export const Newsletter = {
  ...Service('/admin/core/newsletter/')
}

export const SplashScreen = {
  ...Service('/admin/splash-screen/splash-screen/')
}

export const SplashScreenVideo = {
  ...Service('/admin/splash-screen/splash-screen-video/:id'),
  upload: (payload, onUploadProgress) => {
    return axios.post('/admin/splash-screen/splash-screen-video/', payload, {
      headers: { 'content-type': 'multipart/form-data' },
      onUploadProgress: onUploadProgress
    })
  }
}

export const ScheduledNotification = {
  ...Service('/admin/notifications/scheduled-notifications/:id/'),
  revoke(scheduledNotificationId) {
    const url = `/admin/notifications/scheduled-notifications/${scheduledNotificationId}/revoke/`
    return axios.post(url)
  },
  availableStatuses() {
    const url = `/admin/notifications/scheduled-notifications/statuses/`
    return axios.get(url)
  },
  statistics(urlParams, payload) {
    const url = `/admin/notifications/statistics/`
    return axios.get(prepareURL(url, urlParams), payload)
  }
}
