<script>
import { Place } from '@/api'
import ListMixin from '@/crudBases/list/ListMixin'

export default {
  mixins: [ListMixin],
  data() {
    return {
      editRouteName: 'Edit Place',
      addRoute: { name: 'Add Place' },
      verboseName: 'Miejsce',
      verboseNamePlural: 'Miejsca',
      listFields: [
        { prop: 'id', label: 'id', width: 80 },
        { prop: 'name_pl', label: 'Nazwa (pl)' },
        { prop: 'name_en', label: 'Nazwa (en)' }
      ]
    }
  },
  computed: {
    service() {
      return Place
    }
  }
}
</script>
