<script>
import { Event } from '@/api'
import ListMixin from '@/crudBases/list/ListMixin'

export default {
  mixins: [ListMixin],
  data() {
    return {
      editRouteName: 'Edit Event',
      addRoute: { name: 'Add Event' },
      verboseName: 'Wydarzenie',
      verboseNamePlural: 'Wydarzenia',
      listFields: [
        { prop: 'id', label: 'id', width: 80 },
        { prop: 'title_pl', label: 'tytuł (pl)' },
        { prop: 'title_en', label: 'tytuł (en)' },
        { prop: 'slug_pl', label: 'slug (pl)' },
        { prop: 'slug_en', label: 'slug (en)' }
      ]
    }
  },
  computed: {
    service() {
      return Event
    }
  }
}
</script>
