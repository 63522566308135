<template>
  <form-wrapper :disabled="!!form.id && !form.can_edit" @submit="onSubmit">
    <div slot="box" v-loading="loading">
      <h2 v-if="form.id">Szczegóły powiadomienia</h2>
      <div v-if="form.id">
        <h3>Status wysyłki: {{ form.status_display }}</h3>
        <h3 v-if="form.cancelled_at">
          Data anulowania: {{ getPrintableDate(form.cancelled_at) }}
        </h3>
        <h3 v-if="form.sent_at">
          Wysłano o: {{ getPrintableDate(form.sent_at) }}
        </h3>

        <el-popconfirm
          confirm-button-text="Tak, anuluj"
          cancel-button-text="Nie"
          title="Jesteś pewien, że chcesz anulować wysyłkę?"
          @confirm="revokeNotification()"
        >
          <el-button v-if="form.can_edit" slot="reference" type="danger"
            >Anuluj wysyłkę</el-button
          >
        </el-popconfirm>
        <el-divider />
      </div>
      <el-form-item label="Typ" :error="getErrors(errors, ['type'])" required>
        <el-select v-model="form.type" placeholder="Wybierz typ">
          <el-option
            v-for="item in notificationTypes"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        :label="`Tytuł (${lang})`"
        :error="getErrors(errors, [`title_${lang}`])"
        required
      >
        <el-input v-model="form[`title_${lang}`]" />
        <small class="help"
          >Tytuł nie ma limitu znaków, ale w pushu widoczne jest ok. 30
          znaków</small
        >
      </el-form-item>
      <el-form-item
        :label="`Treść (${lang})`"
        :error="getErrors(errors, [`body_${lang}`])"
        required
      >
        <el-input
          v-model="form[`body_${lang}`]"
          type="textarea"
          maxlength="1024"
          show-word-limit
        />
        <small class="help"
          >Treść nie ma limitu znaków, ale w pushu widoczne jest ok. 170
          znaków</small
        >
      </el-form-item>
      <el-row :gutter="24">
        <el-col :md="12" :sm="24">
          <el-form-item
            :label="`Link CTA`"
            :error="getErrors(errors, [`cta_url_${lang}`])"
          >
            <el-input v-model="form[`cta_url_${lang}`]" />
            <small class="help"
              >Jeżeli uzupełniłeś "Link wewnętrzny" pole "Link CTA" i "Treść
              CTA" zostaw puste. Jeśli uzupełniłeś link i treść CTA pole link
              wew. zostaw puste.</small
            >
          </el-form-item>
        </el-col>
        <el-col :md="12" :sm="24">
          <el-form-item
            :label="`Treść CTA`"
            :error="getErrors(errors, [`cta_label_${lang}`])"
          >
            <el-input v-model="form[`cta_label_${lang}`]" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label="Link wewnętrzny">
        <LinkInput
          v-model="form.internal_link"
          :errors="getErrors(errors, ['internal_link'])"
          hide-link-label
        />
      </el-form-item>

      <el-divider content-position="left">Harmonogram wysyłki</el-divider>
      <p v-if="form.id" style="margin-bottom: 25px">
        Nie można zmodyfikować godziny wysyłki po zapisaniu.
      </p>
      <el-form-item>
        <el-radio
          v-model="form.publish_on_save"
          :label="true"
          :disabled="!!form.id"
          >Opublikuj po zapisaniu
        </el-radio>
        <el-radio
          v-model="form.publish_on_save"
          :label="false"
          :disabled="!!form.id"
          >Zaplanuj
        </el-radio>
      </el-form-item>
      <el-form-item
        label="Data i godzina wysyłki"
        :required="!form.publish_on_save"
        :error="getErrors(errors, ['execution_time'])"
      >
        <el-date-picker
          v-model="form.execution_time"
          :disabled="form.publish_on_save || !!form.id"
          type="datetime"
          placeholder="Wybierz datę i godzinę"
        >
        </el-date-picker>
      </el-form-item>
    </div>
    <form-nav
      slot="navbar"
      :loading="loading"
      :redirect-route="redirectRoute"
      @submit="onSubmit"
    >
      <component-compiler
        v-for="(component, i) in getNavButtons()"
        :key="`form-${i}`"
        :content="component"
      />
    </form-nav>
  </form-wrapper>
</template>
<script>
import { ScheduledNotification, Ticket } from '@/api'
import FormMixin from '@/crudBases/form/FormMixin'

export default {
  mixins: [FormMixin],
  data() {
    return {
      redirectRoute: { name: 'ScheduledNotification List' },
      ticketTypes: [],
      notificationTypes: [
        { label: 'Od organizatora', value: 'organizer' },
        { label: 'Promocyjne', value: 'promotional' }
      ],
      form: {
        publish_on_save: true,
        send_to_all: true
      }
    }
  },
  computed: {
    dateRange: {
      set(value) {
        if (value == null) {
          value = [null, null]
        }
        this.form = {
          ...this.form,
          tickets_date_from: value[0],
          tickets_date_to: value[1]
        }
      },
      get() {
        if (this.form.tickets_date_from && this.form.tickets_date_to) {
          return [this.form.tickets_date_from, this.form.tickets_date_to]
        }
        return []
      }
    },
    lang() {
      return this.$store.state.selectedLanguage
    },
    service() {
      return ScheduledNotification
    }
  },
  created() {
    this.fetchTicketTypes()
  },
  methods: {
    revokeNotification() {
      ScheduledNotification.revoke(this.form.id)
        .then(() => {
          this.$message({
            type: 'success',
            message: 'Odwołano wysyłkę powiadomień'
          })
        })
        .catch(() => {
          this.$message({
            type: 'success',
            message: 'Nie udało się odwołać wysyłki powiadomień'
          })
        })
        .finally(() => {
          this.fetchData()
        })
    },
    fetchTicketTypes() {
      Ticket.availableTypes().then(res => {
        this.ticketTypes = res.data
      })
    }
  }
}
</script>
<style scoped>
.help-info {
  color: #737373;
}

.el-divider {
  margin: 35px 0;
}

.el-divider >>> .is-left {
  left: 0;
  padding-left: 0;
  width: 100px;
  color: #474747;
}

.side-help {
  display: inline-block;
  vertical-align: middle;
  margin-left: 20px;
  width: 60%;
}

.help {
  font-weight: 700;
  color: #f56c6c;
}
</style>
