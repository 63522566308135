<template>
  <div>
    <el-tabs>
      <el-tab-pane
        v-for="(tab, i) in tabsFieldsSchema"
        :key="i"
        :label="tab.label"
      >
        <component-compiler
          v-for="(component, x) in tab.fields"
          :key="x"
          :content="component"
        />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import ComponentCompiler from '@/components/ComponentCompiler'
import fields from '@/components/fields/'

export default {
  components: {
    ComponentCompiler
  },
  props: ['form', 'errors'],
  data() {
    return {
      arrayFields: [],
      initialValues: {}
    }
  },
  computed: {
    tabsFieldsSchema() {
      return [
        {
          label: 'Ogólne',
          fields: this.getCommonFields()
        }
      ]
    }
  },
  created() {
    this.fixArrayFields()
    this.setInitialValues()
  },
  methods: {
    getCommonFields() {
      return [
        new fields.Switch({
          label: 'Zapisz jako widget globalny',
          disabled: this.form.id && this.form.is_global,
          modelPath: [this, 'form', 'is_global'],
          help:
            'Zmiany wprowadzone w widgecie globalnym będą widoczne na wszystkich stronach z tym widgetem'
        }),
        new fields.Input({
          label: 'Nazwa widgetu',
          condition: !!this.form.is_global,
          required: true,
          modelPath: [this, 'form', 'working_name']
        }),
        new fields.Switch({
          label: 'Ukryty',
          modelPath: [this, 'form', 'is_hidden']
        })
      ]
    },
    fixArrayFields() {
      this.arrayFields.forEach(field => {
        if (!Array.isArray(this.form[field])) {
          this.$set(this.form, field, [])
        }
      })
    },
    setInitialValues() {
      if (this.form.id) {
        return
      }
      this.$emit('update:form', {
        ...this.form,
        ...this.initialValues
      })
    }
  }
}
</script>
