<script>
import { EventActivityType } from '@/api'
import ListMixin from '@/crudBases/list/ListMixin'

export default {
  mixins: [ListMixin],
  data() {
    return {
      editRouteName: 'Edit EventActivityType',
      addRoute: { name: 'Add EventActivityType' },
      verboseName: 'Rodzaj aktywności wydarzenia',
      verboseNamePlural: 'Rodzaje aktywności wydarzeń',
      listFields: [
        { prop: 'id', label: 'id', width: 80 },
        { prop: 'name_pl', label: 'nazwa (pl)' },
        { prop: 'name_en', label: 'nazwa (en)' },
        { prop: 'slug', label: 'slug' }
      ]
    }
  },
  computed: {
    service() {
      return EventActivityType
    }
  }
}
</script>
