<script>
import { Event, Place, EventCategory, EventActivityType } from '@/api'
import PageFormBase from '@/crudBases/form/PageFormBase'
import fields from '@/components/fields/'
import moment from 'moment'
import PlaceFormModal from './modals/CreatePlaceModal'
import EventCategoryModal from './modals/CreateEventCategoryModal'
import CreateEventActivityTypeModal from './modals/CreateEventActivityTypeModal'
import EventDatePIcker from './EventDatePIcker'

export default {
  mixins: [PageFormBase],
  data() {
    return {
      form: {
        widgets: [],
        dates: [],
        categories: []
      },
      redirectRoute: { name: 'Event List' }
    }
  },
  computed: {
    service() {
      return Event
    },
    currentLanguage() {
      return this.$store.state.selectedLanguage
    },
    isFestival() {
      return this.form.type === 'festival'
    }
  },
  mounted() {
    if (!this.form.id) {
      this.$set(this.form, 'type', 'concert')
    }
  },
  methods: {
    getFields() {
      return [
        {
          component: 'el-tabs',
          children: [
            this.getGeneralTab(),
            this.getDatesTab(),
            this.getWidgetsTab()
            //   this.getSEOTab()
          ]
        }
      ]
    },
    getGeneralTab() {
      return {
        component: 'el-tab-pane',
        attrs: {
          label: 'Ogólne'
        },
        children: [
          new fields.Input({
            label: 'Imię i nazwisko artysty',
            required: true,
            modelPath: [this, 'form', 'artist_full_name'],
            multilingual: true,
            help: 'Wyświetla się w widgecie artyści'
          }),
          new fields.Image({
            label: 'Zdjęcie artysty',
            required: true,
            modelPath: [this, 'form', 'artist_image'],
            multilingual: true,
            help: 'Wyświetla się w widgecie artyści'
          }),
          // new fields.Switch({
          //   label: 'Wszystkie daty wydarzenia są odwołane',
          //   modelPath: [this, 'form', 'all_dates_cancelled']
          // }),
          new fields.Input({
            label: 'Imię i nazwisko / nazwa wydarzenia',
            modelPath: [this, 'form', 'title'],
            type: 'textarea',
            multilingual: true,
            required: true,
            copyTo: {
              og_title_pl: { stripHtml: false, sameLanguage: true },
              og_title_en: { stripHtml: false, sameLanguage: true },
              meta_title_pl: { stripHtml: false, sameLanguage: true },
              meta_title_en: { stripHtml: false, sameLanguage: true }
            },
            help: "Wyświetla się w lineup'ie"
          }),
          new fields.Image({
            label: 'Grafika wydarzenia (lineup)',
            modelPath: [this, 'form', 'image'],
            required: true,
            multilingual: true,
            copyTo: { og_image: { stripHtml: false } },
            help: "Wyświetla się w lineup'ie"
          }),
          new fields.Input({
            label: 'ID utworu spotify',
            modelPath: [this, 'form', 'spotify_track_id']
          }),
          new fields.ForeignKey({
            label: 'Rodzaj aktywności',
            modelPath: [this, 'form', 'activity_type'],
            labelField: 'name_pl',
            valueField: 'id',
            clearable: true,
            allowRefresh: true,
            refreshButtonClassName: 'event-activity-type',
            service: EventActivityType
          }),
          {
            component: CreateEventActivityTypeModal,
            listeners: {
              reload: () => {
                Array.from(
                  document.getElementsByClassName('event-activity-type')
                ).forEach(function (element) {
                  element.click()
                })
              }
            },
            attrs: {}
          },
          new fields.ForeignKey({
            label: 'Kategorie wydarzenia',
            modelPath: [this, 'form', 'categories'],
            labelField: 'name_pl',
            valueField: 'id',
            clearable: true,
            multiple: true,
            sortable: true,
            allowRefresh: true,
            refreshButtonClassName: 'event-category-select',
            service: EventCategory
          }),
          {
            component: EventCategoryModal,
            listeners: {
              reload: () => {
                Array.from(
                  document.getElementsByClassName('event-category-select')
                ).forEach(function (element) {
                  element.click()
                })
              }
            },
            attrs: {}
          }
        ]
      }
    },
    getDatesTab() {
      return {
        component: 'el-tab-pane',
        attrs: {
          label: 'Daty'
        },
        children: [this.getDatesFormset()]
      }
    },
    getWidgetsTab() {
      return {
        component: 'el-tab-pane',
        attrs: {
          label: 'Widgety'
        },
        children: [new fields.Widgets({ modelPath: [this, 'form', 'widgets'] })]
      }
    },
    getSEOTab() {
      return {
        component: 'el-tab-pane',
        attrs: {
          label: 'SEO'
        },
        children: [
          new fields.Input({
            label: 'Slug',
            modelPath: [this, 'form', 'slug'],
            multilingual: true,
            condition: !!this.form.id
          }),
          new fields.Input({
            label: 'Tytuł dla facebooka',
            modelPath: [this, 'form', 'og_title'],
            multilingual: true
          }),
          new fields.Input({
            label: 'Opis dla facebooka',
            modelPath: [this, 'form', 'og_description'],
            type: 'textarea',
            multilingual: true
          }),
          new fields.Image({
            label: 'Zdjęcie dla facebooka',
            modelPath: [this, 'form', 'og_image']
          }),
          new fields.Input({
            label: 'Opis dla wyszukiwarki google',
            modelPath: [this, 'form', 'meta_description'],
            type: 'textarea',
            multilingual: true
          }),
          new fields.Input({
            label: 'Tytuł strony dla wyszukiwarki google',
            modelPath: [this, 'form', 'meta_title'],
            multilingual: true
          })
        ]
      }
    },
    getDatesFormset() {
      return new fields.Formset({
        modelPath: [this, 'form', 'dates'],
        items: this.form.dates.map((item, i) => {
          return {
            headerLabel: item.date
              ? moment(item.date).format('YYYY-MM-DD')
              : 'Nowa data',
            fields: [
              new fields.ForeignKey({
                label: 'Scena / Miejsce',
                modelPath: [this, 'form', 'dates', i, 'place'],
                labelField: 'name_pl',
                valueField: 'id',
                clearable: true,
                service: Place,
                refreshButtonClassName: 'place-select',
                allowRefresh: true,
                required: true
              }),
              {
                component: PlaceFormModal,
                listeners: {
                  reload: () => {
                    Array.from(
                      document.getElementsByClassName('place-select')
                    ).forEach(function (element) {
                      element.click()
                    })
                  }
                },
                attrs: {}
              },
              {
                component: EventDatePIcker,
                listeners: {
                  input: value => {
                    this.$set(this.form.dates, i, value)
                  }
                },
                attrs: {
                  value: this.form.dates[i],
                  errors: (this.errors.dates && this.errors.dates[i]) || {}
                }
              }
              // new fields.Switch({
              //   label: 'Wydarzenie odwołane',
              //   modelPath: [this, 'form', 'dates', i, 'is_cancelled']
              // })
            ]
          }
        })
      })
    }
  }
}
</script>
