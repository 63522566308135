import FieldBase from './FieldBase'

export default class extends FieldBase {
  constructor({ help, disabled, ...args }) {
    super(args)
    const child = this.getComponentWithVModel('el-switch')
    child.attrs = {
      ...child.attrs,
      disabled
    }
    this.children = [child]
    if (help) {
      this.children.push({
        component: 'el-alert',
        attrs: {
          showIcon: true,
          closable: false,
          title: help
        }
      })
    }
  }
}
