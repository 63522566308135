import FieldBase from './FieldBase'

export default class extends FieldBase {
  constructor({ format = 'HH:mm', valueFormat = 'HH:mm', ...args }) {
    super(args)
    const child = this.getComponentWithVModel('el-time-picker')
    child.attrs = {
      ...child.attrs,
      format,
      valueFormat
    }
    this.children = [child]
  }
}
