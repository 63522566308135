<template>
  <div>
    <el-dialog
      title="Edycja zdjęcia"
      append-to-body
      width="90vw"
      top="4vh"
      :visible.sync="dialogFormVisible"
    >
      <image-form :id="id" in-modal @submit="submitForm" />
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelForm()">Anuluj</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import ImageForm from '@/views/Image/ImageForm'

export default {
  components: {
    ImageForm
  },
  data() {
    return {
      dialogFormVisible: false,
      id: null
    }
  },
  methods: {
    openDialog(value) {
      this.id = value
      this.dialogFormVisible = true
    },
    cancelForm() {
      this.dialogFormVisible = false
      this.$emit('cancel')
    },
    submitForm() {
      this.dialogFormVisible = false
      this.$emit('formSubmitted')
    }
  }
}
</script>
