<template>
  <div class="card">
    <span class="card-header">
      <img class="card-icon" :src="iconUrl" alt="" />
      Admin
    </span>
    <ul class="card-items">
      <li v-if="hasPermission('attachments.view_attachment')" class="card-item">
        <router-link tag="el-link" :to="getAttachmentLink()">
          Lista załączników
        </router-link>
      </li>
      <!--            <li class="card-item" v-if="hasPermission('core.edit_sitesettings')">-->
      <!--              <router-link tag="el-link" :to="getSettingsLink()">-->
      <!--                Ustawienia-->
      <!--              </router-link>-->
      <!--            </li>-->
      <li v-if="hasPermission('permissions.view_users_list')" class="card-item">
        <router-link class="page-link" tag="el-link" :to="getUserListLink()">
          Lista użytkowników
        </router-link>
      </li>
      <li v-if="hasPermission('users.add_user')" class="card-item">
        <router-link class="page-link" tag="el-link" :to="getAddUserLink()">
          + Dodaj nowego użytkownika
        </router-link>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  props: {
    iconUrl: {
      type: String,
      default: '/icons/icon-kategoria.png'
    }
  },
  methods: {
    getAttachmentLink() {
      return {
        name: 'Attachments List'
      }
    },
    getUserListLink() {
      return {
        name: 'User List'
      }
    },
    getAddUserLink() {
      return {
        name: 'Add User'
      }
    },
    getSettingsLink() {
      return {
        name: 'Settings Form'
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
