import FieldBase from './FieldBase'

export default class extends FieldBase {
  constructor({
    options,
    multiple = false,
    placeholder = 'Wybierz',
    clearable = true,
    filterable = false,
    allowCreate = false,
    disabled = false,
    ...args
  }) {
    super(args)
    const child = this.getComponentWithVModel('el-select')
    child.attrs = {
      ...child.attrs,
      placeholder,
      clearable,
      multiple,
      filterable,
      allowCreate,
      disabled
    }
    child.children = options.map((option, i) => ({
      component: 'el-option',
      attrs: {
        label: option.label,
        value: option.value,
        key: option.key || i
      }
    }))

    this.children = [child]
  }
}
