<template>
  <div>
    <component
      :is="getFilterComponent(param)"
      v-for="param in listFilters"
      :key="param.query_param"
      v-model="selectedFilters[param.query_param]"
      :param="param"
    />
  </div>
</template>
<script>
import ChoiceFilter from './ChoiceFilter'
import DateFilter from './DateFilter'
import TextFilter from './TextFilter'

export default {
  components: {
    ChoiceFilter,
    DateFilter,
    TextFilter
  },
  props: {
    listFilters: Array,
    selectedFilters: Object
  },
  methods: {
    getFilterComponent(param) {
      if (param.options !== undefined) {
        return 'ChoiceFilter'
      } else if (param.date) {
        return 'DateFilter'
      }
      return 'TextFilter'
    }
  }
}
</script>
<style lang="scss" scoped>
.list-filter {
  margin: 25px 0;

  &-title {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    color: #666666;
  }
}
</style>
