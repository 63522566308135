<template>
  <div>
    <h2 class="title">Lista ostatnio edytowanych stron</h2>
    <el-table :data="recentPages" style="width: 100%">
      <el-table-column prop="id" label="ID" width="60" />
      <el-table-column label="Tytuł" width="400">
        <template slot-scope="scope" class="pull-right">
          <router-link
            v-if="getLinkForPage(scope.row)"
            class="page-link"
            tag="el-link"
            :to="getLinkForPage(scope.row)"
          >
            {{ scope.row.title }}
          </router-link>
          <span v-else>{{ scope.row.title }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Ukryta">
        <template slot-scope="scope" class="pull-right">
          <i
            v-if="scope.row.is_hidden"
            class="icon el-icon-circle-check true"
            title="Tak"
          />
          <i v-else class="icon el-icon-circle-close false" title="Nie" />
        </template>
      </el-table-column>
      <el-table-column
        prop="modified"
        :formatter="formatDate('modified')"
        label="Data modyfikacji"
      />
    </el-table>
  </div>
</template>

<script>
export default {
  props: {
    recentPages: Array
  },
  methods: {
    getLinkForPage(page) {
      const routeForModelName = {
        FlatPage: 'Edit Page',
        Event: 'Edit Event',
        Article: 'Edit Article'
      }
      const routeName = routeForModelName[page.model_name]
      if (!routeName) return
      return {
        name: routeName,
        params: {
          id: page.id
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  font-size: 24px;
  margin-top: 44px;
}
.page-link {
  font-weight: 700;
}
</style>
