<script>
import { EventCategory } from '@/api'
import ListMixin from '@/crudBases/list/ListMixin'

export default {
  mixins: [ListMixin],
  data() {
    return {
      editRouteName: 'Edit EventCategory',
      addRoute: { name: 'Add EventCategory' },
      verboseName: 'Kategoria wydarzenia',
      verboseNamePlural: 'Kategorie wydarzeń',
      listFields: [
        { prop: 'id', label: 'id', width: 80 },
        { prop: 'name_pl', label: 'nazwa (pl)' },
        { prop: 'name_en', label: 'nazwa (en)' },
        { prop: 'slug', label: 'slug' }
      ]
    }
  },
  computed: {
    service() {
      return EventCategory
    }
  }
}
</script>
