<script>
import { SplashScreen, SplashScreenVideo } from '@/api'
import FormMixin from '@/crudBases/form/FormMixin'
import fields from '@/components/fields/'
import CreateSplashScreenVideoModal from '@/views/SplashScreen/modals/CreateSplashScreenVideoModal'

export default {
  mixins: [FormMixin],
  data() {
    return {
      form: {},
      singleton: true,
      redirectRoute: null
    }
  },
  computed: {
    service() {
      return SplashScreen
    }
  },
  methods: {
    getFields() {
      return [
        new fields.Switch({
          label: 'Aktywna',
          modelPath: [this, 'form', 'is_active']
        }),
        // new fields.Image({
        //   label: 'Logo',
        //   required: true,
        //   modelPath: [this, 'form', 'logo'],
        //   multilingual: true
        // }),
        new fields.Image({
          label: 'Grafika',
          modelPath: [this, 'form', 'image'],
          multilingual: true
        }),
        new fields.ForeignKey({
          label: 'Wideo',
          modelPath: [this, 'form', 'video'],
          labelField: 'video',
          valueField: 'id',
          clearable: true,
          allowRefresh: true,
          multilingual: true,
          refreshButtonClassName: 'splash-screen-video',
          service: SplashScreenVideo,
          help:
            'W przypadku dodania grafiki oraz wideo wyświetlone zostanie tylko wideo.'
        }),
        {
          component: CreateSplashScreenVideoModal,
          listeners: {
            reload: () => {
              Array.from(
                document.getElementsByClassName('splash-screen-video')
              ).forEach(function (element) {
                element.click()
              })
            }
          },
          attrs: {}
        }
        // new fields.Image({
        //   label: 'Logo partnera',
        //   modelPath: [this, 'form', 'partner_image'],
        //   multilingual: true
        // })
      ]
    }
  }
}
</script>
