<script>
import BaseWidgetForm from './BaseWidgetForm'
import fields from '@/components/fields/'
import { Event } from '@/api'

export default {
  extends: BaseWidgetForm,
  data() {
    return {
      arrayFields: ['events']
    }
  },
  computed: {
    tabsFieldsSchema() {
      return [
        {
          label: 'Ogólne',
          fields: [
            ...this.getCommonFields(),
            new fields.ForeignKey({
              label: 'Artyści',
              modelPath: [this, 'form', 'events'],
              labelField: 'artist_full_name_pl',
              valueField: 'id',
              clearable: true,
              multiple: true,
              sortable: true,
              allowRefresh: true,
              service: Event
            }),
            new fields.Link({
              label: 'Link więcej',
              modelPath: [this, 'form', 'link_more'],
              required: true,
              internalOnly: true,
              hideLinkLabel: true
            })
          ]
        }
      ]
    }
  }
}
</script>
