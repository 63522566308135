<script>
import BaseWidgetForm from './BaseWidgetForm'
import fields from '@/components/fields/'
import { Place } from '@/api'

export default {
  extends: BaseWidgetForm,
  computed: {
    tabsFieldsSchema() {
      return [
        {
          label: 'Ogólne',
          fields: [
            ...this.getCommonFields(),
            new fields.Input({
              label: 'Tytuł',
              modelPath: [this, 'form', 'title'],
              multilingual: true
            }),
            new fields.Link({
              label: 'Link do lineupu',
              modelPath: [this, 'form', 'link']
            }),
            new fields.Switch({
              label: 'Ręczne uzupełnienie',
              modelPath: [this, 'form', 'is_manual'],
              help: 'W innym wypadku dane zaciągane są z wydarzenia.'
            }),
            new fields.Input({
              label: 'Imię i nazwisko artysty',
              modelPath: [this, 'form', 'artist_full_name'],
              multilingual: true,
              condition: !!this.form.is_manual
            }),
            new fields.Image({
              label: 'Zdjęcie artysty',
              modelPath: [this, 'form', 'artist_image'],
              multilingual: true,
              condition: !!this.form.is_manual
            }),
            new fields.Date({
              label: 'Data',
              type: 'datetime',
              modelPath: [this, 'form', 'date'],
              format: 'yyyy-MM-dd HH:mm',
              condition: !!this.form.is_manual
            }),
            new fields.ForeignKey({
              label: 'Scena / Miejsce',
              modelPath: [this, 'form', 'place'],
              labelField: 'name_pl',
              valueField: 'id',
              clearable: true,
              service: Place,
              condition: !!this.form.is_manual
            })
          ]
        }
      ]
    }
  }
}
</script>
