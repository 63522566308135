<script>
import { Attachment } from '@/api'
import ListMixin from '@/crudBases/list/ListMixin'

export default {
  mixins: [ListMixin],
  data() {
    return {
      verboseName: 'Załącznik',
      verboseNamePlural: 'Załączniki',
      listFields: [
        { prop: 'file', label: 'url' },
        { label: 'data', formatter: this.formatDate('created') }
      ]
    }
  },
  computed: {
    service() {
      return Attachment
    }
  },
  methods: {
    actions() {
      return []
    }
  }
}
</script>
