<template>
  <div>
    <add-global-widget-dialog
      ref="addGlobalWidgetDialog"
      :widgets="widgets"
      @addWidget="addWidget"
    />
    <widget-form-dialog ref="widgetFormDialog" @submit="formDialogSubmitted" />
  </div>
</template>
<script>
import WidgetFormDialog from './widgetFormDialog/'
import AddGlobalWidgetDialog from './addGlobalWidgetDialog'

export default {
  components: {
    WidgetFormDialog,
    AddGlobalWidgetDialog
  },
  props: {
    widgets: Array,
    errors: [Array, String]
  },
  data() {
    return {
      selectedWidget: {},
      previews: []
    }
  },
  beforeMount() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.$store.dispatch('fetchAvailableWidgets')
      this.$store.dispatch('fetchWidgetTypes')
      this.$store.dispatch('fetchGlobalWidget')
    },
    editWidget(widgetIndex) {
      const widget = this.widgets[widgetIndex]
      const errors = this.errors && this.errors[widgetIndex]
      this.$refs.widgetFormDialog.openDialog(widget, errors)
    },
    openAddGlobalWidgetDialog(index) {
      this.index = index
      this.$refs.addGlobalWidgetDialog.openDialog()
    },
    addWidget(newWidget) {
      this.widgets.splice(this.index, 0, newWidget)
    },
    formDialogSubmitted(newForm, oldForm) {
      const index = this.widgets.indexOf(oldForm)
      this.widgets.splice(index, 1, newForm)
    }
  }
}
</script>
<style lang="scss" scoped>
.non-field-error {
  color: $error-color;
}

.buttons {
  position: absolute;
  top: 20px;
  right: 20px;
}
</style>
