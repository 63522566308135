<template>
  <div>
    <p class="list-filter-title">{{ param.name }}</p>
    <el-input
      class="list__search"
      :value="value"
      :placeholder="param.name"
      @input="filterChanged"
    />
  </div>
</template>

<script>
export default {
  props: ['param', 'value'],
  methods: {
    filterChanged(value) {
      this.$emit('input', value)
    }
  }
}
</script>
