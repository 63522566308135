<template>
  <div>
    <m-2-m-order-dialog
      ref="orderDialog"
      :options="options"
      @changeOrder="emitValue"
    />
    <div class="wrapper">
      <el-select
        v-model="selectedValue"
        v-loading="loading"
        filterable
        :clearable="clearable"
        :allow-create="allowCreate"
        :multiple="multiple"
        :disabled="disabled"
        placeholder="Wybierz opcję"
      >
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <el-button
        v-if="allowAddEdit"
        v-loading="loading"
        class="side-button"
        type="info"
        title="Utwórz/edytuj w nowym oknie"
        @click="openEditAddWindow"
      >
        <i class="el-icon" :class="value ? 'el-icon-edit' : 'el-icon-plus'" />
      </el-button>
      <el-button
        v-if="allowRefresh"
        v-loading="loading"
        :class="'side-button ' + refreshButtonClassName"
        title="Odśwież"
        @click="fetchData"
      >
        <i class="el-icon el-icon-refresh"></i>
      </el-button>
      <el-button
        v-if="sortable"
        v-loading="loading"
        class="side-button"
        title="Zmień kolejność"
        @click="openOrderDialog"
      >
        <i class="el-icon el-icon-sort"></i>
      </el-button>
    </div>
  </div>
</template>

<script>
import M2MOrderDialog from './M2MOrderDialog'
import { get } from 'lodash'

export default {
  components: {
    M2MOrderDialog
  },
  props: {
    value: {
      type: [Array, Object, Number, String]
    },
    multiple: {
      type: Boolean,
      default: false
    },
    sortable: {
      type: Boolean,
      default: false
    },
    allowRefresh: {
      type: Boolean,
      default: false
    },
    refreshButtonClassName: {
      type: String,
      default: ''
    },
    allowAddEdit: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    },
    paginated: {
      type: Boolean,
      default: true
    },
    labelField: {
      type: String,
      default: 'name'
    },
    valueField: {
      type: String,
      default: 'id'
    },
    service: {
      type: Object,
      required: true
    },
    allowCreate: {
      type: Boolean,
      default: false
    },
    maxItems: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      options: [],
      loading: false
    }
  },
  computed: {
    selectedValue: {
      set(newValue) {
        if (!this.maxItems || newValue.length <= this.maxItems) {
          this.emitValue(newValue)
        }
      },
      get() {
        return this.value
      }
    },
    editUrl() {
      return ''
    },
    addUrl() {
      return ''
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.loading = true
      const fetchPromise = this.getFetchPromise()
      if (fetchPromise == null) return
      fetchPromise
        .then(response => {
          this.options = this.getPreparedOptions(response.data)
        })
        .finally(() => {
          this.loading = false
        })
    },
    getPreparedOptions(responseData) {
      const prepareOptions = result => ({
        label: get(result, this.labelField),
        value: get(result, this.valueField)
      })
      const results = this.paginated ? responseData.results : responseData
      return results.map(prepareOptions)
    },
    emitValue(value) {
      this.$emit('input', value)
    },
    openOrderDialog() {
      this.$refs.orderDialog.openDialog(this.value)
    },
    getFetchPromise() {
      const params = { page_size: 'max' }
      return this.service.fetch({}, { params })
    },
    openEditAddWindow() {
      if (this.allowAddEdit) {
        const routeDetails = this.value ? this.editUrl : this.addUrl
        const url = this.$router.resolve(routeDetails).href
        const windowOpened = window.open(url)
        const timer = setInterval(() => {
          if (windowOpened.closed) {
            clearInterval(timer)
            this.fetchData()
          }
        }, 500)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
}

.side-button {
  margin-left: 15px;
}
</style>
