<template>
  <form-wrapper @submit="onSubmit">
    <div slot="box" v-loading="loading">
      <el-form-item
        label="Tytuł"
        :error="errors[addLanguageSuffix('title')]"
        required
      >
        <el-input v-model="form[addLanguageSuffix('title')]" />
      </el-form-item>
      <el-form-item
        label="Tekst alternatywny (dla czytników)"
        :error="errors[addLanguageSuffix('alt')]"
      >
        <el-input v-model="form[addLanguageSuffix('alt')]" />
      </el-form-item>
      <el-form-item label="Katalogi">
        <remote-select-input-base
          v-model="form.catalogs"
          :service="catalogService"
          multiple
          allow-create
        />
      </el-form-item>
      <div>
        <h2>Zdjęcie</h2>
        <image-point v-model="form.ppoi" :src="form.file_url" />
        <p>Aby zmienić zdjęcie</p>
        <upload-tab @submit="changeImage" />
      </div>
    </div>
    <form-nav
      slot="navbar"
      :loading="loading"
      :in-modal="inModal"
      modal-label="Edycja zdjęcia"
      :redirect-route="redirectRoute"
      @submit="onSubmit"
    />
  </form-wrapper>
</template>
<script>
import { Catalog, Image } from '@/api'
import FormMixin from '@/crudBases/form/FormMixin'
import UploadTab from '@/components/inputs/ImageDialog/UploadTab'
import RemoteSelectInputBase from '@/components/inputs/RemoteSelectInputBase'
import ImagePoint from './ImagePoint'

export default {
  components: {
    UploadTab,
    ImagePoint,
    RemoteSelectInputBase
  },
  mixins: [FormMixin],
  data() {
    return {
      redirectRoute: { name: 'Image List' }
    }
  },
  computed: {
    service() {
      return Image
    },
    catalogService() {
      return Catalog
    }
  },
  methods: {
    changeImage(image) {
      if (!image) return
      this.$set(this.form, 'file', image)
      this.updateImagePreview()
    },
    updateImagePreview() {
      const value = this.form.file
      const fileType = typeof value
      if (fileType === 'object') {
        const reader = new FileReader()
        reader.onload = e => {
          this.$set(this.form, 'file_url', e.target.result)
        }
        reader.readAsDataURL(this.form.file)
      }
    }
  }
}
</script>
