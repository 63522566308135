<script>
import BaseWidgetForm from './BaseWidgetForm'
import fields from '@/components/fields/'
import { EventCategory } from '@/api'

export default {
  extends: BaseWidgetForm,
  data() {
    return {
      arrayFields: ['categories']
    }
  },
  computed: {
    tabsFieldsSchema() {
      return [
        {
          label: 'Ogólne',
          fields: [
            ...this.getCommonFields(),
            new fields.ForeignKey({
              label: 'Kategorie',
              modelPath: [this, 'form', 'categories'],
              labelField: 'name_pl',
              valueField: 'id',
              clearable: true,
              multiple: true,
              sortable: true,
              allowRefresh: true,
              service: EventCategory
            })
          ]
        }
      ]
    }
  }
}
</script>
