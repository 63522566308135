<script>
import BaseWidgetForm from './BaseWidgetForm'
import fields from '@/components/fields/'
import { Widget } from '@/api'

export default {
  extends: BaseWidgetForm,
  data() {
    return {
      availableIcons: [],
      initialValues: { icon: 'NO_ICON' }
    }
  },
  computed: {
    tabsFieldsSchema() {
      return [
        {
          label: 'Ogólne',
          fields: [
            ...this.getCommonFields(),
            new fields.Image({
              label: 'Zdjęcie',
              modelPath: [this, 'form', 'image'],
              required: true,
              multilingual: true
            }),
            new fields.Select({
              modelPath: [this, 'form', 'icon'],
              label: 'Ikona',
              options: this.availableIcons
            })
          ]
        }
      ]
    }
  },
  created() {
    this.setAvailableIcons()
  },
  methods: {
    setAvailableIcons() {
      Widget.imageWidgetIconChoices().then(response => {
        this.availableIcons = response.data
      })
    }
  }
}
</script>
