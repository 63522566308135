<template>
  <el-form>
    <el-form-item
      :error="getErrors(errors, ['video'])"
      class="drop"
      @dragover.prevent
      @drop="onDrop"
    >
      <label class="btn display-inline">
        PRZECIĄGNIJ I UPUŚĆ LUB
        <input type="file" :accept="accept" @change="onChange" />
      </label>
    </el-form-item>
    <el-progress
      v-if="progress"
      type="circle"
      :percentage="progress"
    ></el-progress>
  </el-form>
</template>
<script>
import { SplashScreenVideo } from '@/api'

export default {
  props: {
    accept: {
      type: String,
      default: 'video/mp4'
    }
  },
  data() {
    return {
      progress: 0,
      errors: {}
    }
  },
  methods: {
    sendFile(files) {
      this.errors = {}
      const data = new FormData()
      const file = files[0]
      data.append('video', file, file.name)
      return SplashScreenVideo.upload(data, event => {
        this.progress = Math.round((100 * event.loaded) / event.total)
      })
        .then(this.uploadSuccess)
        .catch(this.uploadError)
        .finally(() => {
          this.progress = 0
        })
    },
    uploadSuccess(response) {
      this.$emit('submit', response.data)
    },
    uploadError(error) {
      this.errors = error.response.data
      this.$message({
        message: 'Wystąpił problem podczas wysyłania pliku.',
        type: 'error'
      })
    },
    onDrop(e) {
      e.stopPropagation()
      e.preventDefault()
      this.sendFile(e.dataTransfer.files)
    },
    onChange(e) {
      this.sendFile(e.target.files)
    }
  }
}
</script>
<style lang="scss" scoped>
.drop {
  border: 2px dashed #ccc;
  padding: 40px;
  background-color: #fafafa;
  height: 100%;
  max-width: 600px;
  position: relative;
  width: 100%;
  text-align: center;
}
</style>
