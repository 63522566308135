<script>
import BaseWidgetForm from './BaseWidgetForm'
import fields from '@/components/fields/'

export default {
  extends: BaseWidgetForm,
  data() {
    return {
      arrayFields: ['items']
    }
  },
  computed: {
    tabsFieldsSchema() {
      return [
        {
          label: 'Ogólne',
          fields: [
            ...this.getCommonFields(),
            new fields.Input({
              label: 'Tytuł',
              modelPath: [this, 'form', 'header'],
              required: false,
              multilingual: true
            }),
            new fields.Link({
              label: 'Link do strony',
              required: false,
              modelPath: [this, 'form', 'link'],
              internalOnly: true,
              hideLinkLabel: true,
              help: 'Button "więcej"'
            }),
            new fields.Header({
              text: 'Partnerzy',
              type: 'h5'
            }),
            new fields.Formset({
              modelPath: [this, 'form', 'items'],
              items: this.form.items.map((item, i) => ({
                headerLabel: `Partner ${i + 1}`,
                fields: [
                  new fields.Image({
                    label: 'Grafika',
                    required: true,
                    modelPath: [this, 'form', 'items', i, 'image'],
                    multilingual: true
                  }),
                  new fields.Link({
                    label: 'Link do strony',
                    required: true,
                    modelPath: [this, 'form', 'items', i, 'link'],
                    internalOnly: true,
                    hideLinkLabel: true
                  })
                ]
              }))
            })
          ]
        }
      ]
    }
  }
}
</script>
