<template>
  <div>
    <el-tabs>
      <el-tab-pane
        v-for="(tab, i) in tabsFieldsSchema"
        :key="i"
        :label="tab.label"
      >
        <component-compiler
          v-for="(component, x) in tab.fields"
          :key="x"
          :content="component"
        />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import BaseWidgetForm from './BaseWidgetForm'
import NewsPreview from '../NewsPreview'

export default {
  extends: BaseWidgetForm,
  computed: {
    tabsFieldsSchema() {
      return [
        {
          label: 'Ogólne',
          fields: [...this.getCommonFields()]
        },
        {
          label: "Podgląd news'ów",
          fields: [
            {
              component: NewsPreview
            }
          ]
        }
      ]
    }
  }
}
</script>
