import Vue from 'vue'
import App from './App'
import ElementUI from 'element-ui'
import VueRouter from 'vue-router'
import VueI18n from 'vue-i18n'
import locale from 'element-ui/lib/locale/lang/pl'
import store from './store/'
import Vuex from 'vuex'
import router from './router'
import messages from '@/i18n'
import WYSIWYGInput from '@/components/inputs/WYSIWYGInput'
import ImageInput from '@/components/inputs/ForeignKeys/ImageInput'
import AttachmentInput from '@/components/inputs/ForeignKeys/AttachmentInput'
import LinkInput from '@/components/inputs/ForeignKeys/LinkInput'
import '@/mixins'
import Editor from '@tinymce/tinymce-vue'
import SearchListForm from '@/components/SearchListForm'

import 'element-ui/lib/theme-chalk/index.css'

import 'font-awesome/css/font-awesome.min.css'

Vue.use(ElementUI, { locale })
Vue.use(VueRouter)
Vue.use(Vuex)
Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: 'pl', // set locale
  fallbackLocale: 'pl',
  messages // set locale messages
})

// global components
Vue.component('Editor', Editor)
Vue.component('WysiwygInput', WYSIWYGInput)
Vue.component('ImageInput', ImageInput)
Vue.component('AttachmentInput', AttachmentInput)
Vue.component('LinkInput', LinkInput)
Vue.component('SearchListForm', SearchListForm)

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
