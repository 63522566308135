import FieldBase from './FieldBase'

export default class extends FieldBase {
  constructor({ disabled, help, type = 'text', ...args }) {
    super(args)
    const child = this.getComponentWithVModel('el-input')
    child.attrs.type = type
    child.attrs.disabled = disabled
    this.children = [child]
    if (help) {
      this.children.push({
        component: 'small',
        innerText: help,
        attrs: { class: 'field-info' }
      })
    }
  }
}
