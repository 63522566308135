<template>
  <div>
    <div class="grid">
      <card-item
        v-if="hasPermission('events.view_event')"
        header="Wydarzenia"
        :items="recentChangesInModels.events"
        title-kwarg="title_pl"
        add-route-name="Add Event"
        edit-route-name="Edit Event"
        list-route-name="Event List"
      />
      <card-item
        v-if="hasPermission('pages.view_flatpage')"
        header="Strony"
        :items="recentChangesInModels.flatpages"
        title-kwarg="title_pl"
        add-route-name="Add Page"
        edit-route-name="Edit Page"
        list-route-name="Pages List"
      />
      <card-item
        v-if="hasPermission('menu.change_mainmenu')"
        header="Nawigacja"
        add-route-name="Edit MainMenu"
        add-item-name="Edytuj"
        :loader-enabled="false"
      />
      <card-item
        v-if="hasPermission('splash_screen.change_splashscreen')"
        header="Splash screen"
        add-route-name="Edit SplashScreen"
        add-item-name="Edytuj"
        :loader-enabled="false"
      />
      <card-item
        v-if="hasPermission('notifications.change_schedulednotification')"
        header="Powiadomienia"
        :items="recentChangesInModels.notifications"
        title-kwarg="title_pl"
        add-route-name="Add ScheduledNotification"
        edit-route-name="Edit ScheduledNotification"
        list-route-name="ScheduledNotification List"
      />
      <card-item
        v-if="hasPermission('places.view_place')"
        header="Sceny / Miejsca"
        :items="recentChangesInModels.places"
        title-kwarg="name_pl"
        add-route-name="Add Place"
        edit-route-name="Edit Place"
        list-route-name="Place List"
      />
      <admin-card-item />
    </div>
    <recent-pages v-loading="loadingRecentPages" :recent-pages="recentPages" />
  </div>
</template>
<script>
import { Dashboard } from '@/api/'
import RecentPages from './RecentPages'
import CardItem from './Card'
import AdminCardItem from './Card/AdminCard'

export default {
  components: {
    RecentPages,
    CardItem,
    AdminCardItem
  },
  data() {
    return {
      recentPages: [],
      loadingRecentPages: false,
      recentChangesInModels: {}
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      Dashboard.getRecentChangesInModels().then(res => {
        this.recentChangesInModels = res.data
      })

      this.loadingRecentPages = true
      Dashboard.getRecentPages()
        .then(res => {
          this.recentPages = res.data
        })
        .finally(() => {
          this.loadingRecentPages = false
        })
    }
  }
}
</script>

<style lang="scss">
.welcome-text {
  font-size: 36px;
}

.grid {
  padding: 55px;
  display: grid;
  grid-column-gap: 10px;
  grid-row-gap: 20px;
  grid-template-columns: repeat(auto-fit, minmax(360px, 1fr));
}
.card {
  padding-bottom: 35px;
  .loader {
    margin-top: 25px;
    .el-loading-spinner {
      text-align: left;
      padding-left: 54px;
    }
  }
  .card-header {
    display: flex;
    align-items: center;
    font-size: 18px;
    color: #171616;
    .card-icon {
      width: 26px;
      height: 26px;
      object-fit: contain;
      margin-right: 20px;
    }
  }
  .card-items,
  .card-static-items {
    .card-item,
    .card-static-item {
      list-style-type: none;
      margin: 2px 0 8px 14px;
      font-size: 12px;
      color: #171616;
      .el-link--inner {
        font-size: 12px;
        color: #171616;
      }
    }
  }

  .card-static-items .card-static-item {
    .el-link--inner {
      font-weight: bold;
    }
    &:nth-of-type(1) {
      margin-top: 18px;
    }
  }
}
</style>
