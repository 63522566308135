<template>
  <div>
    <el-input v-model="searchFilter" placeholder="Widget..." />

    <el-row
      v-for="(widgets, group) in groupedWidgets"
      :key="group"
      :gutter="12"
    >
      <el-col :span="24">
        <h3>{{ group }}</h3>
      </el-col>
      <transition-group tag="div" name="el-fade-in-linear">
        <el-col v-for="type in widgets" :key="type.polymorphic_ctype" :span="8">
          <el-card
            :key="type.polymorphic_ctype"
            shadow="hover"
            class="type"
            @click.native="selectWidget(type)"
          >
            <img class="thumbnail" :src="getImageForType(type)" alt="" />
            {{ type.label }}
          </el-card>
        </el-col>
      </transition-group>
    </el-row>
  </div>
</template>
<script>
import { groupBy, orderBy } from 'lodash'

export default {
  data() {
    return {
      searchFilter: '',
      step: 1,
      dialogFormVisible: false,
      form: null
    }
  },
  computed: {
    filteredWidgetTypes() {
      const query = this.searchFilter
      return this.$store.state.widgetTypes.filter(type =>
        type.label.includes(query)
      )
    },
    groupedWidgets() {
      const sortedTypes = orderBy(this.filteredWidgetTypes, ['group'], ['desc'])
      return groupBy(sortedTypes, 'group')
    }
  },
  methods: {
    selectWidget(widget) {
      this.searchFilter = ''
      this.$emit('widgetSelected', widget.resourcetype)
    },
    getImageForType(type) {
      return `/widgets/icons/${type.resourcetype}.png`
    }
  }
}
</script>
<style lang="scss" scoped>
.type {
  cursor: pointer;
}

.el-card {
  margin-bottom: 18px;
}

.thumbnail {
  height: 100px;
  width: auto;
  max-width: 100%;
  object-fit: contain;
  display: block;
  margin-bottom: 15px;
}
</style>
