<template>
  <div v-if="src" class="wrapper">
    <div class="point" :style="pointStyle" />
    <img class="thumbnail" :src="src" alt="" @click="selectPoint" />
  </div>
</template>

<script>
export default {
  props: ['value', 'src'],
  computed: {
    coords() {
      if (!this.value) return { x: 0.5, y: 0.5 }
      const splitted = this.value.split('x')
      return {
        x: splitted[0],
        y: splitted[1]
      }
    },
    pointStyle() {
      return {
        left: `${this.coords.x * 100}%`,
        top: `${this.coords.y * 100}%`
      }
    }
  },
  methods: {
    selectPoint(event) {
      const imageClickedRect = event.target.getBoundingClientRect()
      const XCoords =
        (event.clientX - imageClickedRect.left) / imageClickedRect.width
      const YCoords =
        (event.clientY - imageClickedRect.top) / imageClickedRect.height
      const roundedCoords = [XCoords, YCoords].map(coord => coord.toFixed(2))
      this.$emit('input', roundedCoords.join('x'))
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  display: inline-block;
  position: relative;
}

.thumbnail {
  max-width: 500px;
}

.point {
  position: absolute;
  border-radius: 50%;
  content: '';
  border: 1px solid #ff513e;
  background: #ffa34b;
  width: 15px;
  height: 15px;
}
</style>
