<template>
  <div class="wrapper">
    <el-alert
      v-if="inModal"
      title="UWAGA"
      show-icon
      :closable="false"
      description="Po zatwierdzeniu zmiany zostaną zapisane niezależnie od formularza, z którego otwarto modal."
      type="warning"
    >
    </el-alert>
    <div class="nav">
      <div>
        <h1 v-if="!inModal" class="nav--title">{{ getRouteName($route) }}</h1>
        <h1 v-else class="nav--title">{{ modalLabel }}</h1>
      </div>
      <div>
        <language-switcher />
      </div>
      <div>
        <slot>
          <router-link
            v-if="!inModal && redirectRoute"
            :to="{ ...redirectRoute, query: $route.query }"
          >
            <el-button>{{ $t('cancel') }}</el-button>
          </router-link>
          <el-button
            type="primary"
            :disabled="submitDisabled"
            :loading="loading"
            @click="$emit('submit')"
          >
            {{ submitButtonLabel }}
          </el-button>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
import LanguageSwitcher from '@/components/LanguageSwitcher'

export default {
  components: { LanguageSwitcher },
  props: [
    'redirectRoute',
    'modalLabel',
    'loading',
    'inModal',
    'submitLabel',
    'submitDisabled'
  ],
  computed: {
    isRevision() {
      return false
    },
    submitButtonLabel() {
      return this.submitLabel || this.$t('submit')
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  position: sticky;
  top: 0;
  z-index: 3;
  box-shadow: 0px 4px 3px -2px rgba(0, 0, 0, 0.1);
}

.nav {
  padding: 20px;
  align-items: center;
  background: #ffffff;
  display: flex;
  justify-content: space-between;
  & .el-button,
  .el-dropdown {
    margin-left: 10px;
  }

  &--title {
    font-size: 24px;
    margin: 0;
    font-weight: 300;
  }
}
</style>
