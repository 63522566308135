import Vue from 'vue'
import Vuex from 'vuex'
import auth from '@/store/modules/auth'
import { User, Link, Widget } from '@/api'
import { debounce } from 'lodash'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    availableWidgets: {},
    availableLinkOptions: [],
    widgetTypes: [],
    globalWidgets: [],
    fieldTypes: {},
    defaultLanguage: 'pl',
    selectedLanguage: 'pl',
    languages: [
      { code: 'pl', label: 'Polski', flag: '/flags/pl.svg' },
      { code: 'en', label: 'Angielski', flag: '/flags/en.svg' }
    ],
    profiles: [],
    userInfo: { permissions: [] }
  },
  mutations: {
    setAvailableWidgets(state, payload) {
      state.availableWidgets = payload
    },
    setAvailableLinkOptions(state, payload) {
      state.availableLinkOptions = payload
    },
    setWidgetTypes(state, payload) {
      state.widgetTypes = payload
    },
    setGlobalWidgets(state, payload) {
      state.globalWidgets = payload
    },
    setAvailableFields(state, payload) {
      state.availableFields = payload
    },
    setFieldTypes(state, payload) {
      state.fieldTypes = payload
    },
    setProfiles(state, payload) {
      state.profiles = payload
    },
    setUserInfo(state, payload) {
      state.userInfo = payload
    }
  },
  actions: {
    fetchCommon() {
      this.dispatch('fetchUserInfo')
    },
    fetchAvailableWidgets({ commit }) {
      return Widget.available().then(response => {
        commit('setAvailableWidgets', response.data)
      })
    },
    fetchAvailableLinkOptions: debounce(function ({ dispatch }) {
      dispatch('_fetchAvailableLinkOptions')
    }, 500),
    _fetchAvailableLinkOptions({ commit }) {
      Link.availableLinkOptions().then(response => {
        commit('setAvailableLinkOptions', response.data)
      })
    },
    fetchWidgetTypes({ commit }) {
      const widgetsToHide = ['NewsWidget']

      const widgetsHideEnabled = parseInt(
        process.env.VUE_APP_HIDE_DEFINED_WIDGETS
      )
      return Widget.types().then(response => {
        const data = response.data.filter(
          item =>
            !widgetsHideEnabled || !widgetsToHide.includes(item.resourcetype)
        )
        commit('setWidgetTypes', data)
      })
    },
    fetchGlobalWidget({ commit }) {
      return Widget.global().then(response => {
        commit('setGlobalWidgets', response.data)
      })
    },
    fetchProfiles({ commit }) {
      return User.fetchUserProfiles().then(res => {
        commit('setProfiles', res.data)
      })
    },
    fetchUserInfo({ commit }) {
      return User.me().then(res => {
        commit('setUserInfo', res.data)
      })
    }
  },
  getters: {
    hasPerm: state => perm => state.userInfo.permissions.includes(perm)
  },
  setters: {},
  modules: {
    auth
  }
})
