<template>
  <div class="container">
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      label-position="left"
      label-width="0px"
      class="login-container"
      @submit.prevent.native="onSubmit"
    >
      <h3 class="title">Login</h3>
      <el-form-item prop="phone" :error="getErrors(errors, ['phone'])">
        <el-input
          v-model="form.phone"
          type="text"
          :disabled="formSubmitted"
          auto-complete="off"
          placeholder="numer telefonu"
        >
        </el-input>
      </el-form-item>
      <el-form-item style="width: 100%">
        <el-button
          type="button"
          class="el-button el-button--primary"
          style="width: 100%"
          :loading="smsCodeLoading"
          :disabled="smsCodeLoading"
          @click="sendSMSCode"
        >
          <span>WYŚLIJ KOD SMS</span>
        </el-button>
      </el-form-item>
      <el-form-item prop="code" :error="getErrors(errors, ['code'])">
        <el-input
          v-model="form.code"
          type="text"
          auto-complete="off"
          placeholder="kod sms"
        >
        </el-input>
      </el-form-item>
      <el-form-item style="width: 100%">
        <button
          type="submit"
          class="el-button el-button--primary"
          style="width: 100%"
          :loading="loading"
        >
          <span>Login</span>
        </button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { Auth } from '@/api'

export default {
  data() {
    return {
      loading: false,
      smsCodeLoading: false,
      form: {},
      rules: {
        phone: [
          { required: true, message: 'To pole jest wymagane', trigger: 'blur' }
        ],
        code: [
          { required: true, message: 'To pole jest wymagane', trigger: 'blur' }
        ]
      },
      checked: true,
      formSubmitted: false,
      errors: {}
    }
  },
  created() {
    const token = window.localStorage.getItem('token')
    if (token) {
      // user already logged in
      this.redirectOnSuccess()
    }
  },
  methods: {
    async sendSMSCode() {
      this.smsCodeLoading = true
      const payload = { phone: this.form.phone }
      this.errors = {}

      Auth.sendSMSCode(payload)
        .then(response => {
          this.$message({
            message: 'Kod sms został wysłany',
            type: 'success'
          })
          if (response.data.code) {
            this.form.code = response.data.code
          }
        })
        .catch(error => {
          let message = 'Wystąpił błąd podczas logowania.'
          this.errors = error.response.data
          if (error.response.status === 429) {
            message = `${message} ${error.response.data.detail}`
          } else if (error.response.data.non_field_errors) {
            message = `${message} ${error.response.data.non_field_errors}`
          }

          this.$message({
            message: message,
            type: 'error'
          })
        })
        .finally(() => {
          this.smsCodeLoading = false
        })
    },
    async submitLoginForm() {
      this.loading = true
      this.errors = {}

      let payload = {
        phone: this.form.phone,
        code: this.form.code
      }
      Auth.login(payload)
        .then(response => {
          window.localStorage.setItem('token', 'Token ' + response.data.key)
          this.$store.dispatch('fetchCommon')
          this.$store.dispatch('fetchUserInfo').then(() => {
            if (this.$store.state.userInfo.is_staff) {
              this.redirectOnSuccess()
            } else {
              this.$message({
                type: 'error',
                message:
                  'Nie masz wystarczających uprawnień, aby zalogować się do panelu CMS.'
              })
              this.clearToken()
            }
          })
          this.formSubmitted = true
        })
        .catch(error => {
          let message = 'Wystąpił błąd podczas logowania.'
          this.errors = error.response.data
          if (error.response.data.non_field_errors) {
            message = `${message} ${error.response.data.non_field_errors}`
          }

          this.$message({
            message: message,
            type: 'error'
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
    clearToken() {
      window.localStorage.removeItem('token')
    },
    redirectOnSuccess() {
      this.redirectToHomepage()
    },
    onSubmit() {
      this.submitLoginForm()
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  background: #061927;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.login-container {
  margin: 180px auto;
  width: 350px;
  padding: 35px 35px 15px 35px;
  background: #fff;
  border: 1px solid #eaeaea;

  .title {
    margin: 0px auto 40px auto;
    text-align: center;
    color: #505458;
  }

  .remember {
    margin: 0px 0px 35px 0px;
  }
}
</style>
